import background from "../../../images/background.png";
import Traning from "../../../images/training_dev.png";
import Leadership from "../../../images/leadership2.png";
import BusineeDev from "../../../images/business_development.png";

export default function Last() {
  return (
    <div className="flex w-full h-auto justify-center">
      <div
        className="w-full h-auto lg:h-[140vh] rounded-[20px] bg-cover bg-center bg-no-repeat"
        style={{
          backgroundImage: `
            radial-gradient(circle at top left, rgba(255, 255, 255, 0.1) 22%, rgba(255, 255, 255, 0) 20%), 
            url(${background})
          `,
        }}
      >
        <div className="flex flex-col justify-center">
       
          <div className="flex flex-row  w-full  justify-center mt-8 lg:mt-12">
            <div className="flex mr-1">
              <div className="text-2xl xs:text-2.5xl lg:text-4xl font-semibold mt-2 text-white">Our</div>
            </div>
            <div className="w-[100px] lg:w-[120px] xs:h-[4vh] h-[6vh] items-center lg:h-[7vh] bg-white rounded-[20px] flex justify-center mt-1">
              <div className="text-xl lg:text-3xl font-semibold text-[#3479FF]">Team</div>
            </div>
          </div>

      
          <div className="w-full h-auto px-4 lg:px-0">
          
            <div className="w-full h-auto lg:h-[35vh] flex flex-col lg:flex-row justify-between items-center my-4 lg:my-8">
            
              <div className="hidden lg:block w-[30%] lg:ml-[8%] h-[33vh] rounded-[20px]">
                <img
                  src={Traning}
                  alt="Training"
                  className="w-full h-full object-cover  rounded-lg"
                />
              </div>

     
              <div className="w-full lg:w-[40%] h-auto lg:h-[33vh] justify-center flex flex-col pr-[3%]  items-center text-center lg:text-center mt-4 lg:mt-0">
                <div className="text-xl lg:text-3xl font-semibold   text-[#F8A401]">
                  Training & Development
                </div>
                <div className="text-sm lg:text-base font-light  text-white mt-2">
                  The team that empowers trainers and learners—building engaging programs that develop skills, boost confidence, and make learning truly effective.
                </div>
              </div>
            </div>

     
            <div className="w-full h-auto lg:h-[35vh] flex flex-col lg:flex-row justify-between sm:pl-10% items-center my-4 lg:my-8">
   
              <div className="w-full lg:w-[40%] pl-[2%] flex flex-col justify-center h-auto lg:h-[33vh] text-center lg:text-center">
                <div className="text-xl lg:text-3xl font-semibold text-[#F8A401]">
                  Leadership Team
                </div>
                <div className="text-sm lg:text-base font-light text-white mt-2">
                  The visionaries driving our mission forward—strategizing, guiding, and inspiring our teams to innovate, grow, and create meaningful learning experiences that make a lasting impact.
                </div>
              </div>

              
              <div className="hidden lg:block w-[30%] lg:mr-[4%] h-[33vh] rounded-[20px]">
                <img
                  src={Leadership}
                  alt="Leadership"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>
            </div>

      
            <div className="w-full h-auto lg:h-[35vh] flex flex-col lg:flex-row justify-between items-center my-4 lg:my-8">
      
              <div className="hidden lg:block w-[30%] lg:ml-[8%] h-[33vh] rounded-[20px]">
                <img
                  src={BusineeDev}
                  alt="Business Development"
                  className="w-full h-full object-cover rounded-lg"
                />
              </div>

        
              <div className="w-full lg:w-[40%] h-auto pr-[3%] lg:h-[33vh] flex  flex-col justify-center  text-center lg:text-center mt-4 lg:mt-[2%]">
                <div className="text-xl lg:text-3xl  font-semibold text-[#F8A401]">
                  Business Development
                </div>
                <div className="text-sm lg:text-base  font-light text-white mt-2">
                  The bridge between us and our learners—connecting with students, parents, and institutions to understand their needs and offer the best learning solutions.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}