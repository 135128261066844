import juniors from "../../../images/juniors.png";
import strokes from "../../../images/strokes.png";
import squareimage from "../../../images/squareimage.png";
import hexagonimage from "../../../images/hexagonalimage.png";
import yellowribbon from "../../../images/yellowstar.png";
import staricon from "../../../images/staricon.png";
import { motion } from "motion/react";
import BeyondClassroom from "./BeyondClassroom";
import { useEffect } from "react";
export default function NextgenJuniors() {
    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);
  return (
    <div className="relative w-screen min-h-screen">
      <div
        className="w-screen min-h-screen bg-cover bg-center bg-no-repeat flex flex-col  justify-center items-center "
        style={{ backgroundImage: "url('/BG.png')" }}
      >
        <div className="sm:ml-60">
          <div className="flex items-center justify-center">
            <div className="font-bold text-5xl text-black">NEXTGEN</div>
            <img src={juniors} className="w-[30%]" />
          </div>
          <div className="flex gap-2 p-4 justify-center mt-5 lg:mb-5">
            <div className="bg-pink-300 text-black font-bold px-4 rounded-lg flex items-center hover:bg-pink-400">
              Think
            </div>
            <div className="bg-orange-300 text-black font-bold py-2 px-4 rounded-lg hover:bg-orange-400">
              Imagine
            </div>
            <div className="bg-yellow-500 text-black font-bold py-2 px-4 rounded-lg hover:bg-yellow-600">
              Achieve
            </div>
          </div>
          <div className="flex justify-center align-middle mb-30">
            <div className="text-white sm:w-[50%]  align-middle flex justify-center text-center ">
              A program where curiosity meets creativity in an immersive,
              theme-based educational journey for Grades 1-5. Our revolutionary
              approach replaces rote learning with dynamic, interdisciplinary
              exploration, ensuring children grasp concepts in a way that is
              engaging, relevant, and future-ready. With hands-on projects,
              real-world applications, and interactive storytelling, we
              cultivate young minds to think, question, and innovate.
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-screen min-h-screen bg-cover bg-center bg-no-repeat flex flex-col justify-center lg:mt-[-180px] md:mt-[-240px] sm:mt-[-255px] "
        style={{ backgroundImage: "url('/clouds.png')" }}
      >
        <div className="relative flex justify-center items-center lg:mt-[130px] md:mt-[150px] sm:mt-[190px]">
          <div
            className="flex justify-center font-bold text-7xl md:text-6xl sm:text-4xl xs:text-2xl font-Luckiest Guy"
            style={{ color: "#39B3FF", fontFamily: "Luckiest Guy", textShadow: "4px 4px 6px rgba(0, 0, 0, 0.2)"}}
          >
            THeme-BASeD LeARnInG
          </div>
          <div className="absolute top-[-50%] lg:left-[70%] md:left-[90%] sm:left-[75%] xs:left-[80%]  flex flex-col gap-1">
            <img src={strokes} className="md:w-[65%] xs:w-[40%] " />
          </div>
        </div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="flex justify-center mt-2 font-light sm:text-2xl xs:text-[sm] text-center "
          style={{
            fontFamily: "Poppins",
            textShadow: "4px 4px 6px rgba(0, 0, 0, 0.4)",
          }}
        >
          Learning beyond books experiencing beyond limits
        </motion.div>

        <div 
        
          className="relative flex flex-col lg:flex-row items-center justify-between w-full lg:w-[90%] min-h-screen px-4 md:px-10 py-10"
          style={{ fontFamily: "Poppins" }}
        >
          {/* Left Side - Images */}
          <motion.div animate={{ y: [0, -15, 0] }} // Moves up and down
        transition={{
            duration: 2, // Time for one loop
            repeat: Infinity, // Infinite looping
            ease: "easeInOut", // Smooth easing
        }} className="relative w-full lg:w-[650px] h-auto lg:h-[888px] flex items-center justify-center">
            {/* Yellow Hexagon Background */}
            <img
              src={yellowribbon}
              alt="Hexagon"
              className="absolute w-[60%] md:w-[480px] h-auto z-0"
            />

            {/* Fun Learning Star Icon */}
            <img
              src={staricon}
              alt="Fun Learning"
              className="absolute left-0 bottom-10 w-[150px] md:w-[300px] h-auto z-20"
            />

            {/* Main Image inside Hexagon */}
            <img
              src={hexagonimage}
              alt="Main Image"
              className="relative w-[75%] md:w-[430px] left-[-9px] h-auto z-10"
            />

            {/* Right Image */}
            <img
              src={squareimage}
              alt="Right Side Image"
              className="absolute right-0 top-0 w-[150px] md:w-[250px] h-auto z-10"
            />
          </motion.div>

          {/* Right Side - Text Boxes */}
          <div className="w-full mx-2 lg:w-[450px] flex flex-col gap-4 mt-10 lg:mt-0">
            {[
              {
                title: "Igniting Young Visionaries",
                text: "Sparks curiosity and sharpens critical thinking beyond traditional methods.",
                bgColor: "bg-purple-200 hover:bg-purple-300",
              },
              {
                title: "Making Learning Fun & Engaging",
                text: "Real-world themes inspire excitement, motivation, and exploration.",
                bgColor: "bg-purple-400 hover:bg-purple-500",
              },
              {
                title: "Fueling Creativity & Innovation",
                text: "Hands-on projects and storytelling kindle imagination and strengthen problem-solving skills.",
                bgColor: "bg-yellow-300 hover:bg-yellow-400",
              },
              {
                title: "Redefining Traditional Education",
                text: "Moving beyond memorization, our approach makes learning immersive and interactive.",
                bgColor: "bg-blue-400 hover:bg-blue-500",
              },
              {
                title: "Preparing Future Leaders",
                text: "Confidence, adaptability, and a passion for discovery equip kids to thrive in a fast-changing world.",
                bgColor: "bg-blue-300 hover:bg-blue-400",
              },
            ].map((item, index) => (
              <div
                key={index}
                className={`flex items-center gap-4 ${item.bgColor} p-4 rounded-xl shadow-md`}
              >
                <div className="bg-white w-7 h-6 flex items-center text-center justify-center rounded-full font-bold text-lg">
                  {index + 1}
                </div>
                <div>
                  <h3 className="font-bold text-lg">{item.title}</h3>
                  <p className="text-sm text-gray-700">{item.text}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <BeyondClassroom/>
    </div>
  );
}
