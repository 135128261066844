import front from "../../../images/front2.png";
import { FaQuoteRight } from "react-icons/fa";
import { motion } from "motion/react";
import WhyUs from "./WhyUs";
import OurValue from "./OurValue";
import Last from "./Last";
import OurVision from "./OurVision";
import Whatwedo from "./Whatwedo";
import { useEffect } from "react";

export default function AboutUs() {
  const fadeInAnimationVariants = {
    initial: {
      opacity: 0,
      y: 100,
    },
    animate: {
      opacity: 1,
      y: 0,
      transition: {
        delay: 0.05,
        duration: 3,
        repeat: Infinity,
      },
    },
  };

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top of the page
  }, []);

  return (
    <div className="w-screen h-auto flex   flex-col -mt-[3%]  xs:-mt-[5%]">
      {/* Front Image Section */}
      <div>
        <div className="flex justify-center">
          <img src={front} alt="Front" className="w-full sm:h-auto h-[400px]  object-cover" />
        </div>


        {/* Banner Section */}
        <div className="w-screen h-auto bg-[#0652DD] flex justify-center rounded-b-[30px] py-8 sm:py-12">
          <div className="sm:p-6 flex flex-col items-center justify-center">
            <motion.div
              variants={fadeInAnimationVariants}
              initial="initial"
              whileInView="animate"
              viewport={{ once: true }}
              className="flex items-center whitespace-nowrap overflow-hidden"
            >
              <span className="text-white text-2xl xs:text-xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-shadow">
                Empowering Minds
              </span>
              <span className="xs:text-4xl xs:mt-[10%] mx-[1px]  lg:mt-[7%]  flex text-white ">&#700;</span>
              <span className="text-[#fed330] text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-bold text-shadow">
                Shaping Future
              </span>
            </motion.div>

            <motion.div className="w-full sm:w-[65%] h-auto text-white text-center text-sm sm:text-base mt-4 sm:mt-6 hidden sm:block">
              At Simplifying Skills, we are dedicated to transforming the
              educational experience by providing students with opportunities
              that go beyond the traditional classroom. Through innovative,
              world-class programs, we partner with schools, parents, and
              students to nurture holistic development, foster global
              perspectives, and prepare young minds for the challenges and
              opportunities of the future.
            </motion.div>
          </div>
        </div>

        
      <section id="vision">
        <OurVision />
      </section>
      <section id="values">
        {" "}
        <OurValue />
      </section>
      <section id="whatwedo">
        {" "}
        <Whatwedo />
      </section>
      <section id="whyus">
        {" "}
        <WhyUs />
      </section>
      <section id="team">
        <Last />
      </section>

      </div>

    </div>
  );
}

{
/* <OurVision/>
  <OurValue/> 
  <Whatwedo/>
  <WhyUs/>
  <Last/>
*/
}

