import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.scss";
import Nav from "./Component/nav/index"; //navigation bar path
import Footer from "./Component/footer/Footer"; // Import the footer
import Home from "./Component/pages/Home/home"; // Ensure correct path from home page
import CareerAssessment from "./Component/pages/SkillsphereAssessment/CareerAssessment";
import Contact from "./Component/pages/Contact/contact";
import TnC from "./Component/pages/terms-and-conditions";
import PrivacyPolicy from "./Component/pages/privacy-policy";
import Nextgen from "./Component/pages/NextgenInnovator/Nextgen";
import AboutUs from "./Component/pages/AboutUs/AboutUs";
import AboutOurValue from "./Component/pages/AboutUs/OurValue";
import NextgenJuniors from "./Component/pages/NextgenJuniors/NextgenJunior";
import ReferPage from "./Component/pages/ReferPage";
import Contact_Us from "./Component/pages/Contact/Contact-Us/Contact_Us";
import Blog from "./Component/pages/Blog's/Blog";
import Login from "./Component/admin/Login";
import Dashboard from "./Component/admin/DashBoard";
import BlogRegister from "./Component/admin/BlogRegister";
import ProtectedRoute from "./Component/ProtectedRoute";
import Ocean from "./Component/pages/models/Ocean"
import Baronmodel from "./Component/pages/models/Bar_on_model"
import Raisec from "./Component/pages/models/Riasec"
import Describestory from "./Component/pages/Blog's/DescribeStory"
// import JOb_Details from "./Component/pages/careers/Job_Details";
function App() {
  return (
    <>
      {/* <Router> */}
      {/* <Nav />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<Contact />} />
          <Route path="/skillsphere-assessment" element={<CareerAssessment />} />
          <Route path="/terms-and-conditions" element={<TnC />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          {/* <Route path="/skills" element={<Skills />} /> */}
      {/* <Route path="/nextgeni" element={<Nextgen />} />
          <Route path="/about-us" element={<AboutUs />} />
        <Route path="/about-ourvalue" element={<AboutOurValue/>} />
          <Route path="/nextgenj" element={<NextgenJuniors />} />
          <Route path="/refer-earn" element={<ReferPage />} />
          <Route path="/contact" element={<Contact_Us />} />
          <Route path="/blog" element={<Blog/>} />
          {/* <Route path="/JOb_Details" element={<JOb_Details />} /> */}
      {/* <Route path="/login" element={<Login/>} />
        </Routes>
        <Footer /> */}
      <Router>
        <Routes>
          {/* Home */}
          <Route
            path="/"
            element={
              <>
                <Nav />
                <Home />
                <Footer />
              </>
            }
          />

          {/* About */}
          <Route
            path="/about"
            element={
              <>
                <Nav />
                <Contact />
                <Footer />
              </>
            }
          />
         
         <Route
            path="/ocean"
            element={
              <>
                <Nav />
                <Ocean />
                <Footer />
              </>
            }
          />
          <Route
            path="/describestory"
            element={
              <>
                <Nav />
                <Describestory />
                <Footer />
              </>
            }
          />
          {/* SkillSphere Assessment */}
          <Route
            path="/skillsphere-assessment"
            element={
              <>
                <Nav />
                <CareerAssessment />
                <Footer />
              </>
            }
          />
          

          <Route
            path="/riasec"
            element={
              <>
                <Nav />
                <Raisec />
                <Footer />
              </>
            }
          />
          <Route
            path="/baronmodel"
            element={
              <>
                <Nav />
                <Baronmodel/>
                <Footer />
              </>
            }
          />
          {/* Terms & Conditions */}
          <Route
            path="/terms-and-conditions"
            element={
              <>
                <Nav />
                <TnC />
                <Footer />
              </>
            }
          />

          {/* Privacy Policy */}
          <Route
            path="/privacy-policy"
            element={
              <>
                <Nav />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />

          {/* NextGen Innovator */}
          <Route
            path="/nextgeni"
            element={
              <>
                <Nav />
                <Nextgen />
                <Footer />
              </>
            }
          />

          {/* About Us */}
          <Route
            path="/about-us"
            element={
              <>
                <Nav />
                <AboutUs />
                <Footer />
              </>
            }
          />

          {/* About Our Value */}
          <Route
            path="/about-ourvalue"
            element={
              <>
                <Nav />
                <AboutOurValue />
                <Footer />
              </>
            }
          />

          {/* NextGen Juniors */}
          <Route
            path="/nextgenj"
            element={
              <>
                <Nav />
                <NextgenJuniors />
                <Footer />
              </>
            }
          />

          {/* Contact Us */}
          <Route
            path="/contact"
            element={
              <>
                <Nav />
                <Contact_Us />
                <Footer />
              </>
            }
          />

          {/* Blog */}
          <Route
            path="/blog"
            element={
              <>
                <Nav />
                <Blog />
                <Footer />
              </>
            }
          />

          {/* Describe Story */}

          <Route
            path="/login"
            element={
              <>
                <Nav />
                <Login />
                <Footer />
              </>
            }
          />
<Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />

        <Route
          path="/blogregisterpage"
          element={
            <ProtectedRoute>
              <BlogRegister />
            </ProtectedRoute>
          }
        />
        </Routes>
      </Router>
    </>
  );
}

export default App;