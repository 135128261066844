import {motion} from "motion/react"
export default function BlogContact (){

    const handleWhatsAppRedirect = () => {
        const phoneNumber = "917798283335"; 
        const message = encodeURIComponent("Hello, I'm interested in learning more about your services!");
        const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
        
        window.open(whatsappURL, "_blank"); 
      };


    return( 
    <div className="w-full flex flex-col sm:flex-row  lg:h-[32vh] xs:h-[22vh]  items-center justify-center relative">
      <div className="w-full   xs:w-[90%] xs:h-[15vh] lg:h-[16vh] lg:items-center sm:w-[80%] md:w-[73%] lg:w-[73%] xl:w-[73%] xl:h-[20vh] flex justify-end rounded-xl h-[25vh] bg-[#f3a62f]">
        {/* Text Section */}
        <div 
  className="w-full xs:w-auto xs:h-auto xs:justify-center sm:w-[50%] md:w-[60%] xl:text-[30px] lg:text-[16px] md:text-[20px] lg:w-[70%] xl:w-[50%] h-[25vh] lg:text-6xl xs:text-[18px] text-pretty font-bold text-white flex items-center text-3xl px-4"
  style={{ 
    fontFamily: 'Inter', 
    textShadow: "0 0 8px rgba(255, 255, 255, 0.8), 0 0 16px rgba(255, 255, 255, 0.6)" 
  }}
>
  Nurture a reader today, shape a thinker tomorrow.
</div>
        {/* Button Section */}
        <motion.div whileHover={{scale:0.9}} className="w-full xs:w-[70%] xs:h-[15vh] sm:w-[30%] md:w-[40%] lg:w-[40%] xl:w-[30%] h-[25vh] flex  justify-center items-center">
          <div
            onClick={handleWhatsAppRedirect}
            className="w-full xs:w-[100%] xs:h-[5vh] lg:h-[50px] mr-2 font-semibold sm:w-[55%] md:text-[14px] md:w-[55%] xs:text-[11px] sm:text-[16px] lg:w-[60%] xl:w-[55%] h-[8vh] items-center flex rounded-xl lg:text-[20px] pl-4 bg-white text-[#f3a62f] cursor-pointer transition-all duration-300"
          >
            Get In Touch
            <span className="text-[15px] ml-2 lg:text-[25px] xs:text-[11px]">
              &rarr;
            </span>
          </div>
        </motion.div>



      </div>
    </div>
  )
} 