import { Typography } from "@mui/material";
import { FaRocket, FaUserAlt, FaClipboardCheck } from "react-icons/fa";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useState } from "react";

export default function HowItWorks() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    const [hovered, setHovered] = useState("align"); // Middle box hovered by default

    const steps = [
        { id: "assess", icon: <FaRocket size={40} color="#40A9FF" />, title: "Assess", description: "Take an expert designed test to evaluate." },
        { id: "align", icon: <FaUserAlt size={40} color="#FFF" />, title: "Align", description: "Decode your strengths, personality, and ideal career fit." },
        { id: "achieve", icon: <FaClipboardCheck size={40} color="#40C057" />, title: "Achieve", description: "Get personalized guidance to confidently shape your future." },
    ];

    return (
        <div
            style={{
                backgroundColor: "#E8E9FA",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                padding: matches ? "30px 20px" : "50px 50px",
            }}
        >
            <div
                style={{
                    textAlign: "left",
                    marginLeft: matches ? "8%" : "13%",
                }}
            >
                <div
                    style={{
                        display: "flex",
                        alignItems: "baseline",
                        gap: "10px",
                        flexWrap: "nowrap",
                    }}
                >
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 800,
                            fontSize: matches ? "28px" : "35px",
                            color: "#151617",
                            textShadow: "2px 2px 0px rgba(0,0,0,0.4)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        How It
                    </Typography>
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 800,
                            fontSize: matches ? "40px" : "54px",
                            color: "#0072FF",
                            textShadow: "2px 2px 0px rgba(0,0,255,0.2)",
                            whiteSpace: "nowrap",
                        }}
                    >
                        Works
                    </Typography>
                </div>

                <svg
                    width={matches ? "180" : "250"}
                    height="50"
                    viewBox="0 0 250 50"
                    style={{
                        display: "block",
                        marginLeft: "35%",
                        marginTop: "-15px",
                    }}
                >
                    <path
                        d="M20, 20 Q130,0 230,20"
                        stroke="#FA7C07"
                        strokeWidth="4"
                        fill="transparent"
                        strokeLinecap="round"
                    />
                </svg>
            </div>

            <div
                style={{
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    gap: matches ? "20px" : "10px",
                    padding: "20px",
                    borderRadius: "12px",
                }}
            >
                {steps.map((step) => (
                    <div
                        key={step.id}
                        style={{
                            width: hovered === step.id ? (matches ? "95%" : "270px") : (matches ? "90%" : "250px"),
                            height: hovered === step.id ? "230px" : "194px",
                            background: hovered === step.id
                                ? "linear-gradient(180deg, #FFCD4A 0%, #F6A72A 100%)"
                                : "white",
                            borderRadius: "15px",
                            padding: "20px",
                            textAlign: "center",
                            boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
                            transition: "all 0.3s ease-in-out",
                            cursor: "pointer",
                        }}
                        onMouseEnter={() => setHovered(step.id)}
                        onMouseLeave={() => setHovered("align")} // Reset to middle box on hover out
                    >
                        <div
                            style={{
                                display: "flex",
                                justifyContent:'center',
                                marginTop: hovered === step.id ? "5%" : "8%",
                            }}
                        >
                            {step.icon}
                        </div>
                        <h2
                            style={{
                                fontWeight: "bold",
                                marginTop: "10px",
                                color: hovered === step.id ? "black" : "inherit",
                            }}
                        >
                            {step.title}
                        </h2>
                        <p
                            style={{
                                color: hovered === step.id ? "black" : "gray",
                                fontSize: "14px",
                            }}
                        >
                            {step.description}
                        </p>
                    </div>
                ))}
            </div>
        </div>
    );
}
