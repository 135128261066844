import React, { useState } from "react";
import { Link } from "react-router-dom";
import { FaBars, FaSearch, FaUser } from "react-icons/fa";
import simplifyingLogo from "../../images/simplifyinglogo.png";
import { useNavigate } from "react-router-dom";
import image46 from "../../images/refer-icon.svg";
import { FaPhone } from "react-icons/fa";
const Mobile = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [academyOpen, setAcademyOpen] = useState(false);
  const [assessmentOpen, setAssessmentOpen] = useState(false);
  const [clubsOpen, setClubsOpen] = useState(false);
  const [communityOpen, setCommunityOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  
  const closeAllNav = () => {
    setMenuOpen(false);
    setAcademyOpen(false);
    setAssessmentOpen(false);
    setClubsOpen(false);
    setCommunityOpen(false);
    setSearchOpen(false);
    setSearchQuery("");
  };

  const navigate= useNavigate()

  return (
    <nav className="bg-blue-600 p-4  shadow-md  w-full z-50" style={{position:"sticky",top:0}}>
      <div className="flex items-center justify-between">
        {/* Logo */}
        <div className="flex items-center">
          <Link to="/">
            <img src={simplifyingLogo} alt="simplifying skills" className="h-10 w-auto mr-3" />
          </Link>
        </div>

      
        {/* Search, Menu & Login Icons */}
        <div className="flex items-center space-x-4">
          {/* <button className="text-white text-lg" onClick={() => setSearchOpen(!searchOpen)}>
            <FaSearch />
          </button> */}
           <Link
           to="/contact"
            className="text-white bg-blue-900 sm:hidden  px-2 py-2 rounded-lg hover:bg-blue-950 flex items-center"
            target="_blank"
          >
            <FaPhone className=""/>
          </Link>
          <a
            href="https://skillsphere.simplifyingskills.com/login"
            className="text-white bg-blue-900 px-3 py-1 rounded-md hover:bg-blue-950 flex items-center"
            target="_blank"
          >
            <FaUser className="mr-2" /> Login
          </a>
          <button className="text-white text-2xl" onClick={() => setMenuOpen(!menuOpen)}>
            <FaBars />
          </button>
        </div>
      </div>

      {/* Search Bar */}
      {searchOpen && (
        <div className="mt-2 flex">
          <input
            type="text"
            placeholder="Search..."
            className="w-full p-2 rounded-l-md text-gray-700"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
          <button className="bg-white text-blue-600 px-4 py-2 rounded-r-md">
            <FaSearch />
          </button>
        </div>
      )}

      {/* Mobile Menu */}
      {menuOpen && (
        <div className="bg-blue-700 py-2 mt-2 rounded-lg">
          <div className="px-4">
            <ul className="bg-blue-800">
              <li className="px-4 py-2 text-white hover:bg-gray-200 hover:text-black cursor-pointer">
                <Link to="/nextgeni" onClick={closeAllNav}>
                  Nextgen Innovators
                </Link>
              </li>
              <li className="px-4 py-2 text-white hover:bg-gray-200 hover:text-black cursor-pointer">
                <Link to="/nextgenj" onClick={closeAllNav}>
                  Nextgen Juniors
                </Link>
              </li>
              <li className="px-4 py-2 text-white hover:bg-gray-200 hover:text-black cursor-pointer">
                <Link to="/skillsphere-assessment" onClick={closeAllNav}>
                  Skillsphere Assessment
                </Link>
              </li>
            </ul>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Mobile;