import { Paper, Typography, useMediaQuery, useTheme } from "@mui/material";
import arrow from "../../../../images/arrow.png";

export default function PsychometricTraits({ item }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        minHeight: "65vh",
        padding: "20px",
        overflowX: "hidden", 
        maxWidth: "100vw", 
      }}
    >
      <Paper
        elevation={6}
        style={{
          backgroundColor: "#FFF",
          width: matches?"90%":"70%",
          maxWidth: "100vw",
          borderRadius: "30px",
          display: "flex",
          flexDirection: matches ? "column" : "row",
          alignItems: "center",
          justifyContent: "space-between",
          textAlign: "left",
          padding: "30px",
          boxShadow: "0px 6px 20px rgba(0,0,0,0.2)",
          overflowX: "hidden", // Prevent extra width issues
          boxSizing: "border-box", // Ensures no extra width is added
          maxWidth: "100vw", // Ensure it does not exceed viewport width
        }}
      >
        {/* Left Section: Text Content */}
        <div
          style={{
            width: matches ? "100%" : "55%",
            paddingLeft: matches ? "0" : "30px",
            textAlign: "left",
          }}
        >
          <Typography
            style={{
              fontWeight: 900,
              color: "#101534",
              fontSize: matches ? "27px" : "35px",
              lineHeight: "110%",
              fontFamily: "Montserrat",
            }}
          >
            {item.modelname}
          </Typography>

          <Typography
            style={{
              fontWeight: 700,
              color: "#23329B",
              marginTop: "8px",
              fontFamily: "Montserrat",
              fontSize: matches ? "13px" : "18px", // Smaller headline
            }}
          >
            {item.headline}
          </Typography>

          <Typography
            style={{
              fontSize: matches ? "10px" : "14px",
              color: "#767B9C",
              marginTop: "15px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            {item.lowerline1}
          </Typography>

          <Typography
            style={{
              fontSize: matches ? "10px" : "14px",
              color: "#767B9C",
              marginTop: "15px",
              fontFamily: "Montserrat",
              fontWeight: 600,
            }}
          >
            {item.lowerline2}
          </Typography>

          {/* Bullet Points */}
          <div style={{ marginTop: "20px" }}>
  <Typography
    style={{
      fontSize: matches ? "12px" : "14px",
      color: "#333",
      fontFamily: "Montserrat",
      fontWeight: 700,
      marginBottom: "8px",
    }}
  >
    This model will:
  </Typography>

  {item.lowerline3
    ?.split("\n")
    .filter(line => line.trim() !== "") // Remove empty lines
    .map((line, index) => (
      <div
        key={index}
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: "8px",
          justifyContent: "flex-start",
        }}
      >
        <img
          src={arrow}
          alt="Arrow Icon"
          style={{ width: matches ? 10 : 12, height: matches ? 10 : 12, marginRight: 6 }}
        />
        <Typography
          style={{
            fontSize: matches ? "10px" : "12px",
            color: "#767B9C",
            fontFamily: "Montserrat",
            fontWeight: 600,
          }}
        >
          {line.trim()}
        </Typography>
      </div>
    ))}
</div>

        </div>

        {/* Right Section: Image */}
        {!matches && (
          <div
            style={{
              width: "30%",
              display: "flex",
              justifyContent: "center",
              marginTop: matches ? "20px" : "0",
            }}
          >
            <img
              src={item.logo}
              style={{ width: "100%", height: "auto", maxWidth: "250px" }}
            />
          </div>
        )}
      </Paper>
    </div>
  );
}
