import simplifyingLogo from "../../images/simplifyinglogo.png"; 
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import CommentBankOutlinedIcon from '@mui/icons-material/CommentBankOutlined';
import { useNavigate } from "react-router-dom";
import logout from "../../images/logout.png";

export default function Dashboard() {
  const navigate = useNavigate();

  const handleLogout = () => {
    
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("userEmail"); 
    
    
    navigate("/login");
    
    
  };

  return (
    <div className="w-[20%] flex flex-col justify-between items-center gap-5 h-screen bg-white">
      <div>
        <div>
          <img src={simplifyingLogo} alt='logo' className="w-full h-[]"/>
        </div>

        <div 
          onClick={() => navigate("/dashboard")} 
          className="w-[90%] ml-2 items-center pl-[5%] hover:bg-blue-800 bg-gray-200 rounded-lg hover:text-white text-blue-600 flex h-[6vh] cursor-pointer"
        >
          <HomeOutlinedIcon className="text-2xl"/> 
          <div className="text-[15px] ml-[15px]" style={{fontFamily:"Poppins"}}> 
            DashBoard
          </div>
        </div>

        <div 
          onClick={() => navigate("/blogregisterpage")} 
          className="w-[90%] ml-2 mt-2 items-center pl-[5%] hover:bg-blue-800 bg-gray-200 text-blue-600 rounded-lg hover:text-white flex h-[6vh] cursor-pointer"
        >
          <CommentBankOutlinedIcon className="text-2xl"/> 
          <div className="text-[15px] ml-[15px]" style={{fontFamily:"Poppins"}}> 
            Blog
          </div>
        </div>
      </div>
      
      <div 
        onClick={handleLogout} 
        className="w-full mb-3 hover:bg-gray-100 cursor-pointer  p-2 rounded-lg transition mt-auto"
      >
        <img src={logout} alt='logout' className="w-[100px] h-6 mx-auto" />
       
      </div>
    </div>
  );
}