import { motion } from "motion/react";

const OurValue = () => {
  const valueData = [
    { letter: "I", title: "Integrity", desc: "Demonstrate Integrity In All We Do", gradient: "linear-gradient( #A0E1FD, #110B8B)" },
    { letter: "N", title: "Nature", desc: "Nurture A Culture Of Learning", gradient: "linear-gradient( #BCDC55, #62BA14)" },
    { letter: "S", title: "Simplify", desc: "Simplify Processes For Efficiency", gradient: "linear-gradient( #A0E1FD, #65B4FF)" },
    { letter: "P", title: "Passionate", desc: "Be Passionate About Customer Success.", gradient: "linear-gradient( #E54445, #E79693)" },
    { letter: "I", title: "Innovate", desc: "Innovate All The Time.", gradient: "linear-gradient( #FEDD9A, #E09530)" },
    { letter: "R", title: "Resilience", desc: "Show Resilience In Times Of Change.", gradient: "linear-gradient( #E2A2FF, #9028A0)" },
    { letter: "E", title: "Empower", desc: "Empower To Excel.", gradient: "linear-gradient( #F8A401C4 70%, #FFDC4F)" },
  ];

  return (
    <div className="flex flex-col">
      <div
        className="w-full h-auto sm:h-[60vh] text-center flex flex-col justify-center items-center py-8 sm:py-0"
        style={{
          backgroundImage: "radial-gradient(circle at right, #EFDCEF 2%, transparent 17%)",
        }}
      >
        {/* Title */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="flex flex-row justify-center"
        >
          <span className="text-2xl sm:text-3xl md:text-4xl font-bold">Our</span>
          <span className="text-2xl sm:text-3xl md:text-4xl font-bold text-[#0652DD] ml-2">Values</span>
        </motion.div>

        {/* Values Container */}
        <div className="w-[90%] sm:w-[80%] h-auto flex flex-col sm:flex-row justify-center items-center gap-4 sm:gap-2 md:gap-4 pt-8 sm:pt-12">
          {valueData.map(({ letter, title, desc, gradient }) => (
            <motion.div
              key={title}
              className="w-full sm:w-[14%] md:w-[11%] h-[20vh] sm:h-[25vh] md:h-[30vh] rounded-[40px] flex flex-col xs:items-center  md:items-start  justify-center pl-4 sm:pl-6 text-left text-white"
              style={{ backgroundImage: gradient }}
              whileHover={{ boxShadow: "7px 7px #c8cbca" }}
            >
              <div className="text-4xl sm:text-5xl md:text-6xl">{letter}</div>
              <div className="text-sm sm:text-base font-bold mt-2">{title}</div>
              <div className="text-xs sm:text-sm mt-1">{desc}</div>
            </motion.div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OurValue;