import BlogBanner from "./BlogBanner";
import Sotries from "./Stories";
import SectionStory from "./SectionStory";
import BlogContact from "./BlogContact";
import { useEffect, useState } from "react";

export default function Blog() {
  const [apiData, setApiData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch('https://simplifyingskills.com/api/blog/getAllblogs');
        if (!response.ok) throw new Error('Failed to fetch data');
        const data = await response.json();
        setApiData(data);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };
  
    fetchData();
  }, []);
  


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="w-screen h-auto flex flex-col overflow-x-hidden">
      <BlogBanner />
      <Sotries />
      <SectionStory stories={apiData} />
      <BlogContact />
    </div>
  );
}