import React from "react";
import { motion } from "framer-motion";

const Banner = () => {
  const handleWhatsAppRedirect = () => {
    const phoneNumber = "917798283335"; 
    const message = encodeURIComponent("Hello, I'm interested in learning more about your services!");
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;
    
    window.open(whatsappURL, "_blank"); 
  };

  return (
    <motion.div whileHover={{ scale: 1.1 }} className="bg-gradient-to-r from-yellow-400 to-orange-400 p-4 md:p-10 rounded-xl text-center w-fit mx-auto shadow-lg mb-20 mx-3 my-3">
      <h1 className="text-2xl sm:text-4xl font-bold text-white leading-tight" style={{ textShadow: "4px 4px 6px rgba(0, 0, 0, 0.4)" }}>
        For <span className="text-white">Running</span> in 
        <span className="text-blue-500"> School</span>
      </h1>

      <p className="text-sm sm:text-base text-white mt-2">
        Cultivating Skills | Sparking Innovation | Developing Leaders
      </p>

      <button 
        onClick={handleWhatsAppRedirect}
        className="mt-4 cursor-pointer px-4 sm:px-6 py-2 bg-white text-black text-sm sm:text-base font-bold rounded-md shadow-md hover:bg-gray-200 transition duration-300">
        Get In Touch <span className="ml-2">→</span>
      </button>
    </motion.div>
  );
};

export default Banner;
