import Sidebar from "./Sidebar";
import { useState } from "react";

export default function BlogRegister() {
  const [formData, setFormData] = useState({
    storyName: "",
    description: "",
    fullStory: "",
    image: null,
  });

  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState("");

 
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setFormData((prev) => ({ ...prev, image: file }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setMessage("");

    const data = new FormData();
    data.append("storyName", formData.storyName);
    data.append("description", formData.description);
    data.append("fullStory", formData.fullStory);
    if (formData.image) 
      {
      data.append("image", formData.image);
    }

    try {
      const response = await fetch("https://simplifyingskills.com/api/blog/blogPost", {
        method: "POST",
        body: data,
      });

      const result = await response.json();
      if (response.ok) {
        setMessage("Blog posted successfully!");
        setFormData({ storyName: "", description: "", fullStory: "", image: null });
      } 
      
      else {
        setMessage(result.error || "Failed to post blog.");
      }
    } 
    
    catch (error) {
      setMessage("Something went wrong!");
    } 
    
    finally {
      setLoading(false);
    }
  };

  return (
    <div className="w-screen pt-[2%] px-[1%] h-[100vh] bg-[#f1f2f6] flex flex-row">
      <Sidebar />

      <div className="flex pl-5 w-full">
        <form
          className="w-full max-w-6xl bg-white p-6 rounded-lg shadow-md flex gap-6"
          onSubmit={handleSubmit}
          encType="multipart/form-data"
        >
          {/* Left Side Inputs */}
          <div className="flex-1">
            <input
              type="text"
              name="storyName"
              placeholder="Story Name"
              value={formData.storyName}
              onChange={handleChange}
              className="w-full p-2 border rounded mb-4"
              required
            />

            <textarea
              name="fullStory"
              placeholder="Write full story..."
              value={formData.fullStory}
              onChange={handleChange}
              className="w-full p-2 border rounded h-40 mb-4"
              required
            />

            <textarea
              name="description"
              placeholder="Description"
              value={formData.description}
              onChange={handleChange}
              className="w-full p-2 border rounded h-20"
              required
            />
          </div>

          {/* Right Side Inputs */}
          <div className="w-1/3 space-y-4">
            <label className="block p-4 border rounded text-center cursor-pointer bg-gray-100">
              Drag & Drop your files or <span className="text-blue-600">Browse</span>
              <input type="file" className="hidden" onChange={handleFileChange} accept=".png" />
            </label>

            <button
              type="submit"
              className={`w-full p-2 rounded text-white ${
                loading ? "bg-gray-500" : "bg-green-500 hover:bg-green-600"
              }`}
              disabled={loading}
            >
              {loading ? "Posting..." : "Create Post"}
            </button>

            {message && <p className="text-center text-sm text-red-500">{message}</p>}
          </div>
        </form>
      </div>
    </div>
  );
}
