import React from "react"
import Banner from './Banner';// Banner path to dispaly on screen
import ProductSection from "./ProductSection"; //Ensure Correct Path fot Product.
import AdvantagesSection from './AdvantageSection';// path for advantages
import SkillsSection from './SkillsSection';// import skillsection to fetch details and render on screen
import StoriesSection from './StoriesSection';
import TestimonialsSection from './TestimonialsSection';
import WhatOurStudentSays from "./What_Our_Student_Says";
import Gallery from "./Gallery"
import StudentGallery from "./StudentGallery";
const Home = function () {
    return (
        <>
             <Banner />
             <ProductSection />
       <AdvantagesSection />
          <SkillsSection /> 
            <section id="gallery"> <StudentGallery/> </section>
           <StoriesSection />
           <TestimonialsSection /> 
        <Gallery/>   





        </>
    )
}
export default Home;