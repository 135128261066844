import React, { useRef, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Scroll2 from "./Scroll2";

export default function ScrollComponent({ data, setScrollFunction, setActiveIndex }) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const sliderRef = useRef(null);

  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: matches ? false : true,
    beforeChange: (oldIndex, newIndex) => {
      setActiveIndex(newIndex); // Update active index on scroll
    }
  };

  useEffect(() => {
    if (setScrollFunction && sliderRef.current) {
      setScrollFunction(() => (index) => {
        sliderRef.current.slickGoTo(index);
        setActiveIndex(index);
      });
    }
  }, [setScrollFunction]);

  return (
    <div> 
      <Slider ref={sliderRef} {...settings}>
        {data.map((item, index) => (
          <Scroll2 key={index} item={item} />
        ))}
      </Slider>
    </div>
  );
}