import React, { useState } from "react";
import { FaTimes } from "react-icons/fa"; // Close icon
import homeBannerSide from "../../../images/home-banner-side.svg";
import Bannerposter from "../../../images/Bannerposter.png";
import { Link } from "react-router-dom";
import websitevideo from "../../../assets/websitevideo.mp4";
import { motion } from "framer-motion";
import { BiRotateLeft } from "react-icons/bi";

const Banner = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className="w-full h-screen flex flex-col md:flex-row items-center justify-between px-4  sm:px-8 lg:px-16 xl:px-32 relative overflow-hidden">
      {/* Background Video */}
      <video
        autoPlay
        muted
        loop
        className="w-350px md:w-full absolute top-0 left-0 w-full h-full object-cover"
      >
        <source className="w-350px lg:h-auto h-[300px] md:h-[600px] md:w-full" src={websitevideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>

      {/* Semi-transparent overlay to fade the video */}
      <div className="absolute top-0 left-0 w-full h-full bg-black bg-opacity-30"></div>

      {/* Image with white background and opacity */}
      <div className="hidden md:block absolute top-1/2 -translate-y-1/2 -left-12 w-[105%] h-[130%] bg-white bg-opacity-20 p-6 rounded-lg">
        <img src={homeBannerSide} alt="side" className="transform scaleX(-1)" />
      </div>

      {/* Text Section */}
      <div className="max-w-2xl text-center md:text-left my-8 md:my-4 z-10">
        <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-extrabold text-white whitespace-nowrap tracking-wide">
          A School <span className="text-white">Beyond </span>
          <br />
          <span className="text-white">School</span>
        </h1>

        {/* Buttons Section */}
        <div className="mt-6 flex flex-col sm:flex-row items-center justify-center space-y-4 sm:space-y-0 sm:space-x-4">
          {/* Register Now Button */}
          <motion.a
            whileHover={{ scale: 0.9 }}
            href="https://skillsphere.simplifyingskills.com/register"
            target="_blank"
            className="bg-[#734eF4] text-white px-6 py-2 rounded-lg font-bold shadow-lg text-center tracking-wide"
          >
            Register Now
          </motion.a>

          {/* Watch Video Button */}
          <motion.button
            whileHover={{ scale: 0.9 }}
            onClick={() => setIsOpen(true)}
            className="flex bg-[#734eF4]  w-[150px] h-[40px] px-3 rounded-xl items-center space-x-2 text-white font-semibold"
          >
            <motion.span
              whileHover={{
                rotate: 360, // Rotate 360 degrees on hover
                transition: { duration: 2, repeat: Infinity, ease: "linear" }, // Animation settings
              }}
              className="bg-red-500 text-white rounded-full h-8 w-8 flex justify-center items-center p-3 pl-3.5"
            >
              &#9658;
            </motion.span>
            <span className="text-sm">Watch Video</span>
          </motion.button>

        </div>
      </div>

      {/* Image Section (Shifted Right) */}
      <div className="relative w-full flex justify-center md:justify-end ml-auto right-[-10px] sm:right-[-20px] lg:right-[-50px] z-10">
        <img
          alt="banner"
          src={Bannerposter}
          className="w-full max-w-xs sm:max-w-sm md:max-w-lg lg:max-w-xl xl:max-w-4xl opacity-80"
        />
      </div>

      {/* Video Modal */}
      {isOpen && <VideoModal setIsOpen={setIsOpen} />}
    </section>
  );
};

// Video Modal Component
const VideoModal = ({ setIsOpen }) => {
  return (
    <div className="fixed inset-0 flex justify-center items-center bg-black bg-opacity-75 z-50">
      <div className="relative w-full max-w-4xl mx-4">
        {/* Close Button */}
        <button
          onClick={() => setIsOpen(false)}
          className="absolute top-2 right-2 text-white text-2xl"
        >
          <FaTimes />
        </button>

        {/* Embedded YouTube Video */}
        <iframe
          className="w-full h-[50vh] sm:h-[60vh] md:h-[70vh] lg:h-[80vh]"
          src="https://www.youtube.com/embed/WrbwL6J6UII?autoplay=1"
          title="Simplifying Skills Video"
          frameBorder="0"
          allow="autoplay; encrypted-media"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export default Banner;