import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import ai_coding from "../../../images/aicoding.png";
import entrepreneur from "../../../images/Entrepreneurship&leadership.png";
import emotional from "../../../images/EmotionalIntellignece.png";
import innovation from "../../../images/innovation.png";
import robotics from "../../../images/robotics_1.png";
import environment from "../../../images/environment.png";
import financial from "../../../images/financial.png";
import ethical from "../../../images/ethical.png";
import digital_literacy from "../../../images/digital-literacy.png";
import critical_thinking from "../../../images/critical-thinking.png";
import data_literacy from "../../../images/data-literacy.png";
import communication_skills from "../../../images/communication-skills.png";

// Skill Data
const skills = [
  { title: "AI and Robotics", color: "bg-[#AC95FF]", icon: ai_coding },
  { title: "Entrepreneurship and Leadership", color: "bg-[#F5F8FB]", icon: entrepreneur },
  { title: "Emotional Intelligence", color: "bg-[#EDBAFF]", icon: emotional },
  { title: "Innovation and Creativity", color: "bg-[#F5F8FB]", icon: innovation },
  { title: "Coding", color: "bg-[#F5F8FB]", icon: robotics },
  { title: "Environmental Literacy", color: "bg-[#C0FF60]", icon: environment },
  { title: "Financial Literacy", color: "bg-[#F5F8FB]", icon: financial },
  { title: "Ethical and Global Citizenship", color: "bg-[#FFE058]", icon: ethical },
  { title: "Digital Literacy and Information technology", color: "bg-[#FAC8D2]", icon: digital_literacy },
  { title: "Critical Thinking And Problem Solving", color: "bg-[#F5F8FB]", icon: critical_thinking },
  { title: "Data Literacy", color: "bg-[#C9C5FF]", icon: data_literacy },
  { title: "Communication Skills", color: "bg-[#F5F8FB]", icon: communication_skills },
];

// Hook to detect screen size
const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

// Animation Variants
const fadeInAnimationVariants = {
  initial: { opacity: 0, y: 50 },
  animate: (index) => ({
    opacity: 1,
    y: 0,
    transition: { delay: 0.05 * index },
  }),
};

const SkillsSection = () => {
  const isMobile = useScreenSize();

  return (
    <div className="py-12 px-4 text-center bg-white">
      {/* Title */}
      <motion.h2
        whileHover={{ scale: 1.1 }}
        className="text-2xl lg:text-4xl mb-8 lg:mb-16 font-extrabold text-gray-900"
      >
        SKILLS THAT SET YOU <span className="text-orange-500">APART</span>
      </motion.h2>

      {/* Skills List */}
      <motion.div
        variants={fadeInAnimationVariants}
        initial="initial"
        whileInView="animate"
        viewport={{ once: true }}
        className={`mt-6 ${
          isMobile
            ? "grid grid-cols-2 gap-2 max-w-md mx-auto" // Two skill boxes in a row for mobile and tablet
            : "grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-4 gap-4 max-w-6xl mx-auto" // Default grid for larger screens
        }`}
      >
        {skills.map((skill, index) => (
          <motion.div
            key={index}
            className={`flex items-start justify-start text-start align-middle p-2 rounded-md shadow-sm ${
              skill.color
            } text-gray-900 ${
              isMobile ? "w-full" : "w-full" // Full width for mobile and tablet
            }`}
            variants={fadeInAnimationVariants}
            custom={index}
          >
            {/* Icon */}
            <div className="w-8 h-8 flex items-center justify-center bg-white rounded-sm shadow-sm">
              <img src={skill.icon} alt={skill.title} className="w-full p-0.5" />
            </div>

            {/* Skill Title */}
            <p className="ml-2 text-xs sm:text-sm flex align-middle text-pretty items-center   font-medium text-start">
              {skill.title}
            </p>
          </motion.div>
        ))}
      </motion.div>
    </div>
  );
};
export default SkillsSection