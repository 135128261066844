import story from "../../../assets/story.json"
import Lottie from "lottie-react"
import {motion} from "motion/react"

export default function Sotries(){
    return(<div>
        <div className="w-full flex h-auto justify-center relative"   style={{
    backgroundImage:
      "radial-gradient(circle at right, #bbd8f8 2%, transparent 50%), radial-gradient(circle at left, #d38ffd 2%, transparent 28%)",borderTopLeftRadius:30,borderTopRightRadius:30
  }}>
            <div>
            {/* <div className="flex justify-center xs:text-2xl  text-2xl font-semibold" style={{fontFamily:"Roboto",color:"#225EC5"}}>THINGS TO READ</div> */}
            <Lottie loop={true} animationData={story} className="xs:h-[120px] w-full sm:h-[150px]"/><motion.div 
  whileHover={{ scale: 1.1 }} 
  className="flex justify-center mb-3 xs:text-[22px] font-extralight text-center text-2xl" 
  style={{ 
    fontFamily: "Titan One", 
    color: "#2b89f0", 
    textShadow: "2px 2px 4px r rgba(192, 192, 192, 0.6)" 
  }}
>
  Introduce Your Child To The Magical World Of Stories
</motion.div>
            </div>
        </div>
    </div>)
}