import { Typography } from "@mui/material";
import { motion } from "framer-motion";
import target from "../../../../images/Traget.png";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export default function SkillSphere() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    
    return (
        <div
            style={{
                height: matches ? "200px" : "300px",
                backgroundColor: "#E8E9FA",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "90%",
                    gap: matches ? "20px" : "100px",
                }}
            >
                <div style={{ textAlign: matches ? "center" : "left" }}>
                    <div style={{ display: "flex", alignItems: "center", gap: "10px", justifyContent: matches ? "center" : "flex-start" }}>
                        {/* Static "Introducing" Text */}
                        <Typography
                            style={{
                                fontFamily: "Montserrat",
                                fontWeight: 900,
                                fontSize: matches ? "22px" : "44px",
                                color: "#151617",
                                textShadow: "2px 2px 0px rgba(0,0,0,0.4)",
                            }}
                        >
                            Introducing
                        </Typography>
                        
                        {/* Animated "SkillSphere" Text */}
                        <motion.div initial={{ opacity: 0, y: -20, scale: 0.8 }} animate={{ opacity: 1, y: 0, scale: 1 }} transition={{ duration: 0.8, ease: "easeOut" }} whileHover={{ scale: 1.05 }}>
                            <Typography
                                style={{
                                    fontFamily: "Montserrat",
                                    fontWeight: 900,
                                    fontSize: matches ? "25px" : "64px",
                                    color: "#0072FF",
                                    textShadow: "2px 2px 0px rgba(0,0,255,0.2)",
                                }}
                            >
                                SkillSphere
                            </Typography>
                        </motion.div>
                    </div>
                    
                    {/* Animated Underline */}
                    <motion.svg
                        width={matches ? 190 : 500}
                        height={matches ? 20 : 50}
                        viewBox="0 0 500 50"
                        strokeLinecap="round"
                        style={{  display: "block", marginTop: "-10px", marginLeft:matches?"45%":"35%", marginRight: matches ? "auto" : "0" }}
                    >
                        <motion.path
                            d="M50,40 Q250,-5 450,40"
                            stroke="#FA7C07"
                            strokeWidth={matches ? "4" : "6"}
                            fill="transparent"
                            initial={{ pathLength: 0, opacity: 0 }}
                            animate={{ pathLength: 1, opacity: 1 }}
                            transition={{ duration: 1.5, ease: "easeInOut" }}
                            whileHover={{ d: "M50,40 Q250,5 450,40", transition: { duration: 0.8 } }}
                        />
                    </motion.svg>
                    
                    {/* Subtitle */}
                    <Typography
                        style={{
                            fontFamily: "Montserrat",
                            fontWeight: 500,
                            fontSize: matches ? "12px" : "18px",
                            color: "#151617",
                            maxWidth: "550px",
                            marginTop: "10px",
                        }}
                    >
                        A cutting-edge, science-backed assessment that helps students discover
                        their strengths and align them with the ideal career path.
                    </Typography>
                </div>
                
                {/* Animated Floating Image */}
                {!matches && (
                    <motion.div
                        animate={{ y: [0, -15, 0] }}
                        transition={{ duration: 2, repeat: Infinity, ease: "easeInOut" }}
                    >
                        <img src={target} style={{ width: "300px" }} alt="Target" />
                    </motion.div>
                )}
            </div>
        </div>
    );
}