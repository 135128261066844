import first from "../../../images/first.png";
import globe from "../../../images/globe.png";
import girl from "../../../images/girl.png";
import { motion } from "framer-motion"; // Ensure you're using the correct import for motion

const WhyUs = () => {
  return (
    <div>
      <div
        className="flex w-full h-auto mb-20 flex-col lg:mt-[1%]  xs:mt-[5%]"
        style={{
          backgroundImage: "radial-gradient(circle at right, #eafad6 2%, transparent 28%)",
        }}
      >
        {/* Section Title */}
        <div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="w-full flex justify-center text-2xl sm:text-3xl md:text-4xl font-bold"
          >
            <div>Why {" "}</div><span className="text-[#3479FF] ml-2">Us?</span>
          </motion.div>

          {/* Subtitle */}
          <motion.div
            whileHover={{ scale: 1.5 }}
            className="flex justify-center text-center text-lg sm:text-xl md:text-3xl font-semibold text-[#F8A401] text-shadow-md mt-2"
          >
            Because learning isn’t one-size-fits-all!
          </motion.div>
        </div>

        {/* Card Container */}
        <div className="w-full h-[60vh] flex flex-col sm:flex-row sm:flex-wrap justify-start p-4 sm:p-6 md:p-8">
          {/* First Row */}
          <div className="w-full flex flex-col sm:flex-row justify-between gap-4 sm:gap-6 md:gap-8 mb-4 sm:mb-6 md:mb-8">
            {/* Card 1 */}
            <div className="w-full sm:w-[48%] h-[20vh] md:h-[25vh] bg-[#f1d1f8] rounded-lg flex flex-row">
              <div className="w-[28%] h-[20vh] md:h-[25vh]">
                <img src={first} alt="Experiential Learning" className="w-full h-full object-cover" />
              </div>
              <div className="w-[67%] h-[20vh] md:h-[25vh] flex flex-col justify-center p-2 sm:p-4">
                <div className="text-base sm:text-lg md:text-xl font-medium">
                  Experiential Learning Beyond Books
                </div>
                <div className="text-xs sm:text-sm md:text-base">
                  Dive into education that goes beyond books, turning knowledge into real-world impact.
                </div>
              </div>
            </div>

            {/* Card 2 */}
            <div className="w-full sm:w-[48%] h-[20vh] md:h-[25vh] bg-[#CDFFBA] rounded-lg flex flex-row">
              <div className="w-[28%] h-[20vh] md:h-[25vh]">
                <img src={girl} alt="Fellowship" className="w-full h-full object-cover" />
              </div>
              <div className="w-[67%] h-[20vh] md:h-[25vh] flex flex-col justify-center p-2 sm:p-4">
                <div className="text-base sm:text-lg md:text-xl font-medium">
                  Upto ₹2 Crore Fellowship
                </div>
                <div className="text-xs sm:text-sm md:text-base">
                  Harness the power to bring bold, game-changing ideas to life with our exclusive grant.
                </div>
              </div>
            </div>
          </div>

          {/* Second Row */}
          <div className="w-full flex justify-center">
            {/* Card 3 */}
            <div className="w-full sm:w-[48%] h-[20vh] md:h-[25vh] bg-[#FFDCBE] rounded-lg flex flex-row">
              <div className="w-[28%]a h-[20vh] md:h-[25vh]">
                <img src={globe} alt="Global Innovation" className="w-full h-full object-cover" />
              </div>
              <div className="w-[67%] h-[20vh] md:h-[25vh] flex flex-col justify-center p-2 sm:p-4">
                <div className="text-base sm:text-lg md:text-xl font-medium">
                  A Gateway to Global Innovation
                </div>
                <div className="text-xs sm:text-sm md:text-base">
                  Explore revolutionary ideas and discoveries from NASA, Harvard, and MIT, shaping the future of learning.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyUs;