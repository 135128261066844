import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react';
import bg from "../../../../images/image.png";
import Assessment_Focus_bg from "../../../../images/Assessment_Focus_bg.png"
import personalityIcon from "../../../../images/personality.png";
import interestsIcon from "../../../../images/interests.png";
import aptitudeIcon from "../../../../images/aptitude.png";
import strengthsIcon from "../../../../images/Strengthicon.png";
import growthAreasIcon from "../../../../images/growthareas.png";
import careerFitIcon from "../../../../images/careerfit.png";
import skillSphere_Assessment from "../../../../images/skillSphere_Assessment.png";
import boy_laptop from "../../../../images/boy_laptop.png";
import SS_Sample_Assessment_Report from "../../../../assets/SS_Sample_Assessment_Report.pdf"
import skillSphere_Assessment_top from "../../../../images/skillSphere_Assessment_top.png";

export default function AbilityExpertise() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('sm'));
    const [hoveredIndex, setHoveredIndex] = useState(null);

    const handleOpenPDF = () => {
        window.open(SS_Sample_Assessment_Report, "_blank");
    };
    

    return (
        <div>
            {/* Main Section */}
            <div style={{
                fontFamily: 'Montserrat',
                position: 'relative',
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                color: 'white',
                backgroundColor: "#E8E9FA",
                backgroundImage: `url(${bg})`,
                backgroundSize: 'cover',
                padding: matches ? '1rem' : '2rem',
                textAlign: 'center'
            }}>
                <h2 style={{ fontSize: matches ? '28px' : '56px', fontWeight: 900, margin: '2rem 0 0' }}>Personalized Analysis for</h2>
                <h2 style={{ fontSize: matches ? '28px' : '56px', fontWeight: 900, color: '#F5BE4E', marginBottom: '1rem' }}>Uncovering Abilities & Expertise</h2>

                {/* Cards Grid */}
                <div style={{
                    display: 'grid',
                    gridTemplateColumns: matches ? 'repeat(2, 1fr)' : 'repeat(3, 1fr)',
                    gap: '1.5rem',
                    maxWidth: '64rem',
                    width: '100%',
                    padding: '1rem'
                }}>
                    {cards.map((card, index) => (
                        <div
                            key={index}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(null)}
                            style={{
                                backgroundColor: 'white',
                                color: 'black',
                                padding: matches ? '15px' : '30px',
                                borderRadius: '1rem',
                                boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
                                transform: hoveredIndex === index ? 'translateY(-5px)' : 'translateY(0)',
                                transition: 'transform 0.3s ease',
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center',
                                textAlign: 'center',
                                cursor: 'pointer',
                            }}>
                            <div style={{ backgroundColor: card.color, borderRadius: "15px", marginBottom: matches ? "0" : '10px' }}>
                                <img src={card.icon} alt={card.title} style={{ width: '50px', height: '50px' }} />
                            </div>
                            <span style={{ fontSize: '18px', fontWeight: 'bold' }}>{card.title}</span>
                            <p style={{ fontSize: '14px', color: '#4B5563' }}>{card.description}</p>
                        </div>
                    ))}
                </div>

                {/* Report Preview Section */}
                <h1 style={{ fontSize: matches ? '28px' : '48px', fontWeight: 900, marginTop: '5%', color: '#F5BE4E' }}>See How The Report Will Look!</h1>
                <div style={{ display: 'flex', flexDirection: matches ? 'column' : 'row', alignItems: 'center', gap: '2rem' }}>
                    {!matches && <img src={boy_laptop} style={{ width: '350px', height: 'auto' }} />}
                    <div style={{ backgroundColor: "#fff", width: '100%', maxWidth: '400px', borderRadius: '25px', position: 'relative' }}>
                        <img src={skillSphere_Assessment} style={{ width: '100%', borderRadius: '25px', filter: 'blur(5px)' }} />
                        <div style={{ width: '100%', position: 'absolute', top: '15%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <img src={skillSphere_Assessment_top} style={{ borderRadius: '25px' }} />
                        </div>
                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                            <a href="#" onClick={handleOpenPDF}>
                                <button
                                    style={{
                                        backgroundColor: 'red',
                                        color: 'white',
                                        padding: '10px 20px',
                                        fontSize: '16px',
                                        border: 'none',
                                        borderRadius: '10px',
                                        cursor: 'pointer',
                                        boxShadow: '2px 2px 10px rgba(0,0,0,0.2)',
                                    }}
                                >
                                    CLICK HERE
                                </button>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <div
                style={{
                    backgroundImage: `url(${Assessment_Focus_bg})`, // Corrected syntax
                    backgroundSize: 'cover',
                    backgroundPosition: 'top',
                    padding: matches ? '1rem' : '2rem 1rem',
                    width: '100%',
                    height: matches ? '70vh' : '100vh',
                    position: 'relative',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                {/* Heading */}
                <h2
                    style={{
                        fontSize: matches ? '22px' : '34px',
                        fontWeight: 'bold',
                        textAlign: 'center',
                        marginBottom: matches ? '5px' : '20px',
                    }}
                >
                    <span style={{ color: '#1078E7' }}>Assessment Focus:</span>
                    <span style={{ color: '#101534' }}> A Glimpse of the Process</span>
                </h2>

                {/* YouTube Video inside the grey frame */}
                <div
                    style={{
                        width: matches ? '90%' : '70%',
                        height: matches ? '60%' : '70%',
                        borderRadius: '35px',
                        overflow: 'hidden',
                        position: 'relative',
                        border: '7px solid #007bff',
                    }}
                >
                    <iframe
                        src="https://www.youtube.com/embed/xkSnhyBzAUg?autoplay=1&loop=1&mute=1&playlist=xkSnhyBzAUg&controls=0&modestbranding=1&disablekb=1"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        style={{
                            width: '100%',
                            height: '100%',
                            borderRadius: '28px',
                            pointerEvents: "none",
                        }}
                    ></iframe>
                </div>
            </div>
        </div>
    );
}

const cards = [
    { title: "Personality", description: "Behavioral traits and emotional intelligence.", icon: personalityIcon, color: '#ef4444' },
    { title: "Interests", description: "Hobbies and subject preferences.", icon: interestsIcon, color: '#6366f1' },
    { title: "Aptitude", description: "Problem-solving and reasoning skills.", icon: aptitudeIcon, color: '#ef4444' },
    { title: "Strengths", description: "Core skills and natural talents.", icon: strengthsIcon, color: '#6366f1' },
    { title: "Growth Areas", description: "Key skills to improve further.", icon: growthAreasIcon, color: '#ef4444' },
    { title: "Career Fit", description: "Matching profiles to career paths.", icon: careerFitIcon, color: '#6366f1' }
];
