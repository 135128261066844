import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import nextgenibgvedio from "../../../assets/nextgenibgvedio.mp4"

export default function RegisterNow() {
    const theme = useTheme();
        const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
        const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    return (
            <div
                id="register-container"
                style={{
                    width: "100%",
                    minHeight: "280px",
                    height: isMobile ? "200px" : isTablet ? "400px" : "500px",
                    backgroundColor: "#173C7D9E",
                    position: "relative",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    overflowY: "auto",
                    overflowX: "hidden",
                    padding: isMobile ? "5px" : "20px",
                    boxSizing: "border-box",
                    
                }}
            >
                {/* Video Background */}
                <video
                    autoPlay
                    muted
                    loop
                    style={{
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                        objectFit: "cover", 
                        zIndex: 0,
                        pointerEvents: "none"
                    }}
                >
                    <source src={nextgenibgvedio} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
                </div>
    )
}