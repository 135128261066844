import visionImg from "../../../images/ourVisionimg.png";
import gogo from "../../../images/gogo.png";
import gogo2 from "../../../images/gogo2.png";
import mission from "../../../images/mission.png";

export default function   OurVision() {
  return (
    <div className="flex w-screen flex-col items-center justify-center bg-white h-auto">
      <div
        className="w-screen"
        style={{
          backgroundImage: "radial-gradient(circle at top right, #FFFAB6 1%, transparent 25%)",
        }}
      >
        {/* Vision Section */}
        <div className="w-screen h-auto sm:h-[55vh] flex flex-col sm:flex-row items-center justify-center py-8 sm:py-0">
          {/* Vision Card */}
          <div className="w-[90%] sm:w-[70%] h-auto sm:h-[36vh] bg-[#eae8fa] flex flex-col sm:flex-row items-center justify-center rounded-2xl p-4 relative">
            {/* Overlapping Vision Image (Hidden on Mobile and Tablet) */}
            <img
              src={visionImg}
              alt="Vision"
              className="hidden sm:block w-[70%] lg:w-[480px] sm:w-[32%] h-[30vh] sm:h-[44vh] rounded-full sm:rounded-none absolute -right-[10%] top-1/2 transform -translate-y-1/2"
              style={{
                zIndex: 1, // Ensure the image is above the card
              }}
            />

            {/* Centered Content */}
            <div className="flex flex-col w-full sm:w-[50%] items-center mr-auto sm:items-center justify-center text-center sm:text-center">
              {/* Title Row */}
              <div className="flex flex-row items-center justify-center">
                {/* Hide gogo image on mobile and tablet */}
                <img
                  src={gogo}
                  alt="Vision Icon"
                  className="hidden sm:block lg:w-[130px] lg:h-[15vh] sm:w-[15%] h-[8vh] sm:h-[12vh]"
                />
                  <div className="text-2xl sm:text-3xl md:text-4xl font-semibold">
                  
                  <span className="text-5xl">Our</span><span className="text-[#3479FF] text-5xl ml-2">Vision</span>
                
              </div>
              </div>

              {/* Description */}
              <div className="w-full sm:w-[80%] text-sm sm:text-base">
                Redefine learning to ignite curiosity, foster innovation, and empower the next generation to lead with
                impact and creativity.
              </div>
            </div>
          </div>
        </div>

        {/* Mission Section */}
        <div className="w-screen h-auto sm:h-[55vh] flex flex-col  sm:flex-row items-center justify-center py-8 sm:py-0">
          {/* Mission Card */}
          <div className="w-[90%] sm:w-[70%] h-auto sm:h-[36vh] bg-[#eae8fa] flex flex-col sm:flex-row items-center justify-center rounded-2xl p-4 relative">
            {/* Overlapping Mission Image (Hidden on Mobile and Tablet) */}
            <img
              src={mission}
              alt="Mission"
              className="hidden sm:block w-[70%] lg:w-[480px] sm:w-[32%] h-[30vh] sm:h-[44vh] rounded-full sm:rounded-none absolute -left-[10%] top-1/2 transform -translate-y-1/2"
              style={{
                zIndex: 1, // Ensure the image is above the card
              }}
            />

            {/* Centered Content */}
            <div className="flex flex-col w-full sm:w-[60%] items-center ml-auto sm:items-center justify-center text-center sm:text-center">
              {/* Title Row */}
              <div className="flex flex-row items-center justify-center">
                {/* Hide gogo2 image on mobile and tablet */}
                <img
                  src={gogo2}
                  alt="Mission Icon"
                  className="hidden sm:block lg:w-[130px] lg:h-[15vh] sm:w-[15%] h-[8vh] sm:h-[12vh]"
                />
                <div className="text-2xl sm:text-3xl md:text-4xl font-semibold">
                  
                    <span className="text-5xl">Our</span><span className="text-[#3479FF] text-5xl ml-2">Mision</span>
                  
                </div>
              </div>

              {/* Description */}
              <div className="w-full sm:w-[80%] text-sm sm:text-base ">
                Revolutionize learning through immersive experiences by equipping individuals with essential skills,
                encouraging creativity, and inspiring leadership, all while nurturing their potential to drive meaningful
                change and innovation in the world.
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
}