import React, { useState, useEffect } from "react";
import simplifyingLogo from "../../images/simplifyinglogo.png";
import { Link, useNavigate, useLocation } from "react-router-dom";

const Desktop = () => {
  const [activeSkill, setActiveSkill] = useState(false);
  const [activeNextgenJ, setActiveNextgenJ] = useState(false);
  const [activeNextgenI, setActiveNextgenaI] = useState(false);
  const navigate = useNavigate();
  const location = useLocation(); // Hook to get the current route

  // Reset active states when the route changes
  useEffect(() => {
    const path = location.pathname;
    if (path !== "/skillsphere-assessment" && path !== "/nextgeni" && path !== "/nextgenj") {
      setActiveSkill(false);
      setActiveNextgenJ(false);
      setActiveNextgenaI(false);
    }
  }, [location.pathname]); // Trigger effect when the route changes

  const handleActiveSkill = () => {
    setActiveSkill(true);
    setActiveNextgenJ(false);
    setActiveNextgenaI(false);
  };

  const handleActiveInnovator = () => {
    setActiveSkill(false);
    setActiveNextgenJ(false);
    setActiveNextgenaI(true);
  };

  const handleActiveJunior = () => {
    setActiveSkill(false);
    setActiveNextgenJ(true);
    setActiveNextgenaI(false);
  };

  return (
    <nav className="bg-blue-600 shadow-md flex items-center pt-2 w-screen z-50" style={{ position: "sticky", top: 0 }}>
      <div className="h-4 bg-blue-950 absolute top-0 left-0 w-5/6 -z-0 rounded-br-3xl"></div>

      <Link to="/">
        <div className="flex items-center h-full z-10 bg-white rounded-tr-3xl overflow-hidden">
          <img src={simplifyingLogo} alt="Logo" className="h-full w-52" />
        </div>
      </Link>

      <div className="flex-1 flex mx-6">
        <div>
          <Link to="/skillsphere-assessment">
            <button
              onClick={handleActiveSkill}
              className={`px-4 py-2 ${
                activeSkill ? "bg-gray-200 text-black" : "hover:bg-gray-200 hover:text-black text-white"
              } cursor-pointer rounded-md md:w-[110px] md:text-[12px] lg:text-[15px] lg:w-fit`}
            >
              SKILLSPHERE ASSESSMENT
            </button>
          </Link>
          <Link to="/nextgeni">
            <button
              onClick={handleActiveInnovator}
              className={`px-4 py-2 ${
                activeNextgenI ? "bg-gray-200 text-black" : "hover:bg-gray-200 hover:text-black text-white"
              } cursor-pointer rounded-md md:w-[120px] lg:w-fit md:text-[12px] lg:text-[15px]`}
            >
              NEXTGEN INNOVATORS
            </button>
          </Link>
          <Link to="/nextgenj">
            <button
              onClick={handleActiveJunior}
              className={`px-4 py-2 ${
                activeNextgenJ ? "bg-gray-200 text-black" : "hover:bg-gray-200 hover:text-black text-white"
              } cursor-pointer rounded-md md:w-[120px] lg:w-fit md:text-[12px] lg:text-[15px]`}
            >
              NEXTGEN JUNIORS
            </button>
          </Link>
        </div>
      </div>

      <div className="flex justify-center">
        <button
          className="bg-blue-900 absolute right-[5%] bottom-4 text-white px-4 py-2 rounded-md md:mr-[7%] font-bold mr-2 hover:bg-[#130f40]"
          onClick={() => navigate("/contact")}
        >
          Contact Us
        </button>
      </div>

      <div className="flex items-center gap-x-3">
        <a
          target="_blank"
          href="https://skillsphere.simplifyingskills.com/login"
          className="bg-blue-900 text-white xl:px-4 md:px-3 py-2 absolute hover:bg-[#130f40] rounded-md font-bold right-[4%] mr-2"
        >
          LOGIN
        </a>
      </div>
    </nav>
  );
};

export default Desktop;