import design1 from "../../../images/design1.svg";
import Slider from "./Slider";
import DynamicClubs from "./DynamicClubs";
import GlobalExposure from "./GlobalExposure";
import FellowshipGrant from "./FellowshipGrant";
import Banner from "./Banner";
import { useEffect } from "react";
import Added_Vedio from "./Added_vedio"

export default function Nextgen() {

    useEffect(() => {
      window.scrollTo(0, 0); // Scroll to the top of the page
    }, []);

  return (
    <div className="w-screen h-auto flex flex-col items-center justify-center">
      <Added_Vedio />
      <div className="flex items-baseline justify-center text-center w-full mt-6">
        <div className="relative inline-block">
          <span className="text-[6vw] font-bold text-[rgb(250,168,38)] leading-tight">
            Courses
          </span>
          <img
            src={design1}
            alt="Underline"
            className="absolute bottom-[-5px] left-0 w-full max-w-[100%]"
          />
        </div>

        <span className="text-[#0F1D33] text-[3vw] font-bold ml-2">
          We Offer
        </span>
      </div>
      
      <Slider />
      <DynamicClubs />
      <GlobalExposure />
      <FellowshipGrant />
      <Banner />
    </div>
  );
}
