"use client";

import g1 from "../../../images/gal1.jpg";
import g2 from "../../../images/gal2.jpg";
import g3 from "../../../images/gal3.jpg";
import g4 from "../../../images/gal4.jpg";
import g5 from "../../../images/gal5.jpg";
import g6 from "../../../images/gal6.jpg";
import g7 from "../../../images/gal7.jpg";
import g8 from "../../../images/gal8.jpg";
import g9 from "../../../images/gal9.jpg";
import g10 from "../../../images/gal10.jpg";
import g11 from "../../../images/gal11.jpg";
import g12 from "../../../images/gal12.jpg";
import g13 from "../../../images/addonn13.jpg";
import g14 from "../../../images/addonn14.jpg";
import g15 from "../../../images/addonn15.jpg";
import g16 from "../../../images/addonn16.jpg";
import g17 from "../../../images/addonn17.jpg";
import g18 from "../../../images/addonn18.jpg";
import g19 from "../../../images/addonn19.jpg";
import g20 from "../../../images/addonn20.jpg";
import g21 from "../../../images/addonn21.jpg";
import g22 from "../../../images/addonnl22.jpg";
import g23 from "../../../images/addonnl23.jpg";
import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";

export default function FallingSlideshow() {
  const [index, setIndex] = useState(0);
  const [direction, setDirection] = useState(1); // 1 for forward (right to left), -1 for backward (left to right)

  const nextSlide = () => {
    setDirection(1); // Set direction to forward (right to left)
    setIndex((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setDirection(-1); // Set direction to backward (left to right)
    setIndex((prev) => (prev - 1 + images.length) % images.length);
  };

  const images = [
    g1, g2, g3, g4, g5, g6, g7, g8, g9, g10, g11, g12, g13, g14, g15, g16, g17, g18, g19, g20, g21, g22, g23,
  ];

  useEffect(() => {
    const interval = setInterval(nextSlide, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div
      className="relative w-full max-w-3xl my-5 mx-auto flex flex-col items-center"
      style={{
        backgroundImage:
          "radial-gradient(circle at left, #baf988 1%, transparent 20%), radial-gradient(circle at right, lightblue 1%, transparent 25%)",
        backgroundColor: "white",
      }}
    >
      <motion.h2
        whileHover={{ scale: 1.3 }}
        className="text-3xl md:text-4xl font-bold text-orange-500 mb-6"
      >
        GALLERY
      </motion.h2>

      {/* Container for image and mobile arrows */}
      <div className="relative w-[340px] md:w-[550px] h-[300px] overflow-hidden rounded-lg shadow-lg">
        <AnimatePresence mode="sync" custom={direction}>
          <motion.img
            key={index}
            src={images[index]}
            alt={`Slide ${index + 1}`}
            custom={direction}
            initial={{ x: direction === 1 ? "100%" : "-100%", opacity: 0 }} // Start from the right or left based on direction
            animate={{ x: 0, opacity: 1 }} // Move to the center
            exit={{ x: direction === 1 ? "-100%" : "100%", opacity: 0 }} // Exit to the left or right based on direction
            transition={{ duration: 0.5, ease: "easeInOut" }} // Faster transition
            className="absolute w-full h-full object-cover"
          />
        </AnimatePresence>

        {/* Arrows for mobile screens (inside the image container) */}
        <button
          onClick={nextSlide}
          className="absolute left-2 top-1/2 transform -translate-y-1/2 bg-blue-600 hover:bg-blue-950 text-white px-4 py-2 rounded-md lg:hidden"
        >
          &larr;
        </button>
        <button
          onClick={prevSlide}
          className="absolute right-2 top-1/2 transform -translate-y-1/2 bg-blue-600 hover:bg-blue-950 text-white px-4 py-2 rounded-md lg:hidden"
        >
          &rarr;
        </button>
      </div>

      {/* Arrows for larger screens (inside the radial-gradient div) */}
      <button
        onClick={prevSlide}
        className="absolute left-0 top-1/2 transform -translate-y-1/2 bg-blue-600 hover:bg-blue-950 text-white px-4 py-2 rounded-md hidden lg:block"
        style={{ left: "10px" }} // Adjust this value to position the arrow inside the radial-gradient div
      >
        &larr;
      </button>
      <button
        onClick={nextSlide}
        className="absolute right-0 top-1/2 transform -translate-y-1/2 bg-blue-600 hover:bg-blue-950 text-white px-4 py-2 rounded-md hidden lg:block"
        style={{ right: "10px" }} // Adjust this value to position the arrow inside the radial-gradient div
      >
        &rarr;
      </button>

      <div className="mt-4 md:flex hidden justify-center gap-2">
        {images.map((_, i) => (
          <div
            key={i}
            className={`w-3 h-3 rounded-full ${
              i === index ? "bg-blue-500" : "bg-gray-300"
            }`}
            onClick={() => setIndex(i)}
          ></div>
        ))}
      </div>
    </div>
  );
}