import blogbanner1 from "../../../images/blogbanner.png";
import blogbanner2 from "../../../images/blogbanner2.png";
import { motion } from "motion/react";

export default function BlogBanner() {
  return (
    <div className="w-full h-auto flex flex-col overflow-x-hidden"> {/* Changed w-screen to w-full and added overflow-x-hidden */}
      <div className="w-full"> {/* Added w-full to contain children */}
        <div 
          className="flex justify-center relative w-full" 
          style={{ 
            backgroundImage: `url(${blogbanner1})`, 
            backgroundSize: 'cover', 
            backgroundPosition: 'center',
            overflow: 'hidden' // Added to prevent any overflow
          }}
        >
          <img 
            src={blogbanner2} 
            alt="Front" 
            className="w-full sm:h-[600px] h-[400px] object-cover" // Changed w-screen to w-full
          />
          <div className="absolute inset-0 flex flex-col items-center justify-end">
            <motion.h1 
              whileHover={{ scale: 1.1 }} 
              className="sm:text-6xl xs:text-2xl text-center md:text-4xl lg:text-6xl xs:font-medium font" 
              style={{ 
                fontFamily: "Titan One", 
                color: "#FFDC4F", 
                textShadow: "2px 2px 4px rgba(255, 255, 255, 0.8)" 
              }}
            >
              Explore Ideas, Ignite Change
            </motion.h1>
            <p className="sm:text-2xl text-white sm:mt-1 mb-2">Discover stories that shape success.</p>
          </div>
        </div>
      </div>
    </div>
  );
}