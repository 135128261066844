import React, { useState, useEffect, useRef } from "react";
import { FaVolumeUp, FaVolumeMute, FaPlay, FaPause } from "react-icons/fa"; // Icons for volume and play/pause
import { motion, AnimatePresence } from "framer-motion";

// Import video files
import Reel1 from "../../../assets/Reel1.mp4";
import Reel2 from "../../../assets/Reel2.mp4";
import Reel3 from "../../../assets/Reel3.mp4";
import Reel4 from "../../../assets/Reel4.mp4";
import Reel5 from "../../../assets/Reel5.mp4";

// Custom hook to detect screen size
const useScreenSize = () => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth < 768);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return isMobile;
};

const StoriesSection = () => {
  const isMobile = useScreenSize();
  const initialIndex = isMobile ? 1 : 1; // Start with index 1 for mobile (centered video), 1 for larger screens
  const [activeVideoIndex, setActiveVideoIndex] = useState(initialIndex);
  const [isMuted, setIsMuted] = useState(true); // Mute/unmute state
  const videoRefs = useRef([]); // Refs for video elements

  const videoArray = [Reel1, Reel2, Reel3, Reel4, Reel5];

  // Handle video end event
  const handleVideoEnd = () => {
    const nextIndex = (activeVideoIndex + 1) % videoArray.length; // Move to the next video
    setActiveVideoIndex(nextIndex); // Update the active video index
  };

  // Handle click on a video to make it the centered video
  const handleVideoClick = (index) => {
    setActiveVideoIndex(index); // Set the clicked video as active
  };

  // Autoplay the active video and pause others when activeVideoIndex changes
  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === activeVideoIndex) {
          video.play(); // Play the active video
        } else {
          video.pause(); // Pause all other videos
        }
      }
    });
  }, [activeVideoIndex]);

  // Get the videos to display based on screen size
  const getVisibleVideos = () => {
    if (isMobile) {
      // For mobile, show 3 videos: previous, active, and next
      const prevIndex = (activeVideoIndex - 1 + videoArray.length) % videoArray.length;
      const nextIndex = (activeVideoIndex + 1) % videoArray.length;
      return [videoArray[prevIndex], videoArray[activeVideoIndex], videoArray[nextIndex]];
    } else {
      // For larger screens, show 3 videos: left, center, right
      const prevIndex = (activeVideoIndex - 1 + videoArray.length) % videoArray.length;
      const nextIndex = (activeVideoIndex + 1) % videoArray.length;
      return [videoArray[prevIndex], videoArray[activeVideoIndex], videoArray[nextIndex]];
    }
  };

  return (
    <div
      className="py-16 px-6 w-[100%] text-center bg-gradient-to-b from-gray-100 to-gray-200 overflow-hidden"
      style={{
        backgroundImage:
          "radial-gradient(circle at bottom, #90c7fd 20%, transparent 50%)",
        backgroundColor: "white",
      }}
    >
      {/* Title */}
      <motion.h2
        whileHover={{ scale: 1.3 }}
        className="text-2xl lg:text-4xl mb-8 lg:mb-10 font-extrabold text-gray-900"
      >
        <span style={{ color: "black" }}>STORIES</span>{" "}
        <span className="text-orange-500">THAT INSPIRE</span>
      </motion.h2>

      {/* Carousel Container */}
      <div className="relative flex justify-center items-center gap-8 top-7 overflow-hidden">
        {/* Render visible videos */}
        <AnimatePresence initial={false}>
          {isMobile ? (
            // Vertical list for mobile
            <div className="flex flex-col gap-10 items-center"> {/* Increased gap to 10 */}
              {getVisibleVideos().map((video, index) => {
                const isCentered = index === 1; // Center video is active
                const videoIndex =
                  index === 0
                    ? (activeVideoIndex - 1 + videoArray.length) % videoArray.length // Previous video
                    : index === 1
                    ? activeVideoIndex // Current video
                    : (activeVideoIndex + 1) % videoArray.length; // Next video

                return (
                  <div
                    key={index}
                    className="relative cursor-pointer"
                    onClick={() => handleVideoClick(videoIndex)} // Handle click to make the video centered
                  >
                    <VideoCard
                      size={isCentered ? "large" : "small"} // Center video is large
                      videoUrl={video}
                      isCentered={isCentered}
                      isMuted={isMuted}
                      onVideoEnd={isCentered ? handleVideoEnd : null}
                      ref={(el) => (videoRefs.current[videoIndex] = el)}
                    />
                    {/* Play/Pause Button for Non-Centered Videos */}
                    {!isCentered && (
                      <button
                        className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70 transition-all duration-300 text-5xl"
                        onClick={(e) => {
                          e.stopPropagation(); // Prevent the parent div's onClick from firing
                          handleVideoClick(videoIndex);
                        }}
                      >
                        <FaPlay />
                      </button>
                    )}
                    {/* Mute/Unmute Button for Centered Video (Mobile Only) */}
                    {isCentered && isMobile && (
                      <button
                        className="absolute bottom-4 left-1/2 transform -translate-x-1/2 p-3 bg-orange-500 text-white rounded-full text-2xl" 
                        onClick={() => setIsMuted(!isMuted)}
                      >
                        {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
                      </button>
                    )}
                  </div>
                );
              })}
            </div>
          ) : (
            // Carousel for larger screens
            getVisibleVideos().map((video, index) => {
              const isCentered = index === 1; // Only the center video is active
              const isLeft = index === 0; // Left video
              const isRight = index === 2; // Right video

              return (
                <div key={index} className="relative">
                  <VideoCard
                    size={isCentered ? "large" : "small"} // Center video is large
                    videoUrl={video}
                    isCentered={isCentered}
                    isMuted={isMuted}
                    onVideoEnd={isCentered ? handleVideoEnd : null}
                    ref={(el) => (videoRefs.current[activeVideoIndex + index - 1] = el)} // Correct ref assignment
                  />
                  {/* Play/Pause Button for Left and Right Reels */}
                  {(isLeft || isRight) && (
                    <button
                      className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-4 bg-black bg-opacity-50 rounded-full text-white hover:bg-opacity-70 transition-all duration-300 text-5xl"
                      onClick={() => {
                        const targetIndex = isLeft
                          ? (activeVideoIndex - 1 + videoArray.length) % videoArray.length
                          : (activeVideoIndex + 1) % videoArray.length;
                        handleVideoClick(targetIndex);
                      }}
                    >
                      {isCentered ? <FaPause /> : <FaPlay />}
                    </button>
                  )}
                </div>
              );
            })
          )}
        </AnimatePresence>
      </div>

      {/* Mute/Unmute Button for Large Screens */}
      {!isMobile && (
        <div className="mt-10 flex justify-center"> {/* Increased margin-top to mt-10 */}
          <button
            className="p-3 bg-orange-500 text-white rounded-full text-2xl"
            onClick={() => setIsMuted(!isMuted)}
          >
            {isMuted ? <FaVolumeMute /> : <FaVolumeUp />}
          </button>
        </div>
      )}
    </div>
  );
};

// Reusable Video Card Component
const VideoCard = React.forwardRef(
  ({ size, videoUrl, isCentered, isMuted, onVideoEnd }, ref) => {
    // Adjust the size of the videos
    const cardSize =
      size === "large"
        ? "w-64 h-96 lg:w-80 lg:h-[28rem]" // Larger size for centered video
        : "w-40 h-64 lg:w-56 lg:h-80"; // Smaller size for side videos

    return (
      <motion.div
        className={`relative ${cardSize} bg-gray-400 overflow-hidden transform transition-transform duration-300 ${
          isCentered
            ? "scale-110 rounded-2xl" // Centered video styling
            : "scale-100 rounded-lg hover:scale-105 hover:shadow-lg transition-all duration-300" // Hover effect for side videos
        }`}
        initial={{ opacity: 0, x: isCentered ? 0 : (size === "small" ? -100 : 100) }}
        animate={{ opacity: 1, x: 0, scale: isCentered ? 1.1 : 1 }}
        exit={{ opacity: 0, x: isCentered ? 0 : (size === "small" ? -100 : 100) }}
        transition={{ duration: 0.5, ease: "easeInOut" }}
        whileHover={{ scale: isCentered ? 1.15 : 1.05 }} // Add hover effect
      >
        <video
          ref={ref}
          className="w-full h-full object-cover"
          src={videoUrl}
          muted={isMuted}
          autoPlay={isCentered} // Autoplay only the centered video
          onEnded={onVideoEnd}
          loop={false} // Disable loop to handle video end event
        />
      </motion.div>
    );
  }
);

export default StoriesSection;