import React, { useState } from "react";
import { motion } from "framer-motion";
import design15 from "../../../images/design15.svg";
import design16 from "../../../images/design16.svg";
import image14 from "../../../images/image14.svg";
import design17 from "../../../images/design17.svg";
import design18 from "../../../images/design18.svg";
import design19 from "../../../images/design19.svg";
import design21 from "../../../images/design21.svg";
import design20 from "../../../images/design20.svg";
import design22 from "../../../images/design22.svg";
import design23 from "../../../images/design23.svg";
import image15 from "../../../images/image15.svg";
import image16 from "../../../images/image16.svg";
import image17 from "../../../images/image17.svg";
import image18 from "../../../images/image18.svg";
import image19 from "../../../images/image19.svg";

const clubs = [
  {
    id: 1,
    title: "Entrepreneurship League",
    subtitle: "Dream, Build, Lead – Shape the World!",
    description:
      "Got a game-changing idea? This club is for bold innovators, future CEOs, and aspiring entrepreneurs ready to turn their visions into reality. Develop leadership skills, master business strategies, and create ventures that make an impact.",
    icon: design16,
    image: image14,
  },
  {
    id: 2,
    title: "Engineering Explorers",
    subtitle: "Innovate Today, Engineer Tomorrow!",
    description:
      "If building, problem-solving, and pushing technology forward excite you, this club is your launchpad. Perfect for future engineers, tech enthusiasts, and inventors, it challenges you to create real-world solutions and transform ideas into innovations.",
    icon: design19,
    image: image15,
  },
  {
    id: 3,
    title: "Medical Mavericks",
    subtitle: "Healing Lives, Inspiring Futures!",
    description:
      "Passionate about medicine, healthcare, and making a difference? Whether you dream of being a doctor, researcher, or medical pioneer, this club connects you with groundbreaking discoveries and real-world medical advancements.",
    icon: design20,
    image: image16,
  },
  {
    id: 4,
    title: "Artistic Visionaries",
    subtitle: "Create, Express, Inspire – The Future is Yours!",
    description:
      "For creative minds, trendsetters, and artistic souls, this club is your canvas. Whether you’re into painting, music, writing, or design, explore bold ideas, push creative boundaries, and turn inspiration into art.",
    icon: design21,
    image: image17,
  },
  {
    id: 5,
    title: "Business Trailblazers",
    subtitle: "Master Strategies, Lead the Game!",
    description:
      "Think like a strategist, analyze like an economist, and lead like an entrepreneur. If finance, marketing, and business innovation fuel your ambition, this club sharpens your skills and prepares you for the competitive business world.",
    icon: image18,
    image: design22,
  },
  {
    id: 6,
    title: "Administrative Achievers",
    subtitle: "Learn with Integrity, Govern with Impact!",
    description:
      "Born to lead? Whether you’re passionate about public speaking, governance, or making a difference, this club is your gateway to shaping policies, leading communities, and driving change on a bigger stage.",
    icon: design23,
    image: image19,
  },
];

const DynamicClubs = () => {
  const [selectedClub, setSelectedClub] = useState(null);

  return (
    <div
      className="w-full bg-cover bg-top bg-no-repeat flex flex-col items-center mb-8"
      style={{ backgroundImage: "url('/wavebg.png')" }}
    >
      <div className="mt-24 text-center font-bold">
        <motion.div whileHover={{ scale: 1.1 }} className="text-4xl sm:text-5xl text-yellow-400" style={{ textShadow: "4px 4px 6px rgba(0, 0, 0, 0.7)" }}>
          Discover | Learn | Grow
        </motion.div>
        <motion.div whileHover={{ scale: 1.1 }} className="text-3xl sm:text-4xl mt-4 text-white">
          With Our Dynamic Clubs
        </motion.div>
      </div>

      <div className="flex flex-col lg:flex-row items-center">
        <img src={design15} className="w-60 lg:w-80 hidden lg:block" />

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 p-6">
          {clubs.map((club) => (
            <motion.div
              whileHover={{ scale: 1.1 }}
              key={club.id}
              className="bg-white w-64 md:w-72 h-36 rounded-lg flex items-center shadow-lg cursor-pointer"
              onClick={() => setSelectedClub(club)}
            >
              <div className="flex items-center px-4">
                <div className="relative mr-2">
                  <img src={club.icon} className="h-40" />
                  <img src={club.image} className="absolute top-[31px] left-[-1px] w-100" />
                </div>

                <div>
                  <div className="text-lg font-bold">{club.title}</div>
                  <div className="text-gray-500 text-sm font-medium">
                    {club.subtitle}
                  </div>
                </div>
              </div>
            </motion.div>
          ))}
        </div>

        <img src={design18} className="w-64 lg:w-72 hidden lg:block" />
      </div>

      {/* Expanded Club Details */}
      {selectedClub && (
        <motion.div
          className="fixed inset-0 bg-black bg-opacity-60 flex justify-center items-center z-50"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
        >
          <motion.div
            className="bg-white p-6 rounded-xl shadow-2xl max-w-2xl w-[90%] flex flex-col sm:flex-row items-center relative"
            initial={{ scale: 0.8 }}
            animate={{ scale: 1 }}
          >
            <button
              className="absolute top-2 right-4 text-3xl rounded-full w-8 h-8 flex items-center justify-center"
              onClick={() => setSelectedClub(null)}
            >
              ✖
            </button>

            {/* Left Side - Image */}
            <div className="relative mr-2">
                  <img src={selectedClub.icon} className="h-40" />
                  <img src={selectedClub.image} className="absolute top-[10px] left-[-1px] w-120" />
                </div>

            {/* <div className="sm:w-1/3 flex justify-center">
              <img src={selectedClub.image} className="h-36 rounded-lg shadow-md" />
            </div> */}

            {/* Right Side - Content */}
            <div className="sm:w-2/3 sm:pl-6 text-center sm:text-left">
              <h2 className="text-2xl font-bold text-black">{selectedClub.title}</h2>
              <h3 className="text-lg font-semibold text-orange-500 mt-1">{selectedClub.subtitle}</h3>
              <p className="text-gray-600 mt-2">{selectedClub.description}</p>
            </div>
          </motion.div>
        </motion.div>
      )}
    </div>
  );
};

export default DynamicClubs;
