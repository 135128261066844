import design24 from "../../../images/design24.svg";
import character from "../../../images/character.png";
import { motion } from "motion/react";

export default function GlobalExposure() {
  return (
    <div className="w-full flex flex-col items-center justify-center mx-auto">
      {/* Title */}
      <div
        className="w-screen h-auto"
        style={{
          backgroundImage: `radial-gradient(circle at right, #FFEB3B 7%, transparent 10%)`,
        }}
      >
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="text-[#1789ef] text-4xl md:text-6xl font-bold text-center mt-3"
        >
          Global Exposure
        </motion.div>
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="text-black text-lg md:text-3xl font-normal text-center mt-2"
        >
          Experience the world and learn from the best!
        </motion.div>
        <div>
          <img src={design24} className="w-[50%] mt-15 mx-auto" alt="Design" />
          <div className="text-[#facc15] text-3xl text-center md:text-6xl justify-center flex font-bold mt-6">
            Why This Exposure Matters?
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row justify-center items-center w-full  bg-[#f8fbff] ">
        {/* Left Side - Cards */}
        <div className="flex justify-center items-center w-full mt-10 mb-10 bg-[#f8fbff] ">
          {/* Cards Grid */}
          <div className="relative grid grid-cols-1 md:grid-cols-2 gap-6 md:gap-x-8 md:gap-y-16 w-full max-w-[350px] md:max-w-[650px] ">
            {/* Upper Row */}
            <motion.div  whileHover={{ scale: 1.1 }}
              className="bg-peterriver w-full m-2 h-[200px] flex flex-col justify-center items-center p-4 md:p-6 shadow-lg rounded-xl"
              style={{ fontFamily: "Montserrat" }}
            >
              <div
                className=" text-lg text-center md:text-xl font-semibold"
                style={{ fontFamily: "Montserrat" }}
              >
                📡 Expert Insights
              </div>
              <p
                className="text-gray-600  text-sm  text-center"
                style={{ fontFamily: "Montserrat" }}
              >
               Learn from the best and gain insights from some of the brightest minds in science, technology, and leadership. 
              </p>
            </motion.div>

            <motion.div  whileHover={{ scale: 1.1 }} className="bg-peterriver w-full h-[200px] flex flex-col justify-center items-center p-4 md:p-6 shadow-lg rounded-xl">
              <div
                className=" text-lg text-center md:text-xl font-semibold"
                style={{ fontFamily: "Montserrat" }}
              >
                🔬 Innovative Research
              </div>
              <p
                className="text-gray-600 mt-2 text-sm md:text-base text-center"
                style={{ fontFamily: "Montserrat" }}
              >
                Expand your horizons by exploring cutting-edge research and breakthrough innovations.
              </p>
            </motion.div>

            {/* Overlapping Center Card (Desktop) */}
            <motion.div className="absolute hidden md:flex w-[260px] h-[180px] bg-[#fff7f3] p-4 shadow-lg rounded-xl border border-gray-200 z-10 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 flex-col justify-center items-center text-center">
              <div
                className=" text-lg text-center md:text-xl font-semibold"
                style={{ fontFamily: "Montserrat" }}
              >
                🎓 Exclusive Access
              </div>
              <p
                className="text-gray-600  text-sm "
                style={{ fontFamily: "Montserrat" }}
              >
                Join an exclusive group of selected Simplifying Skills students on this prestigious journey.
              </p>
            </motion.div>

            {/* Lower Row */}
            <motion.div  whileHover={{ scale: 1.1 }} className="bg-peterriver w-full h-[200px] flex flex-col justify-center items-center p-4 md:p-6 shadow-lg rounded-xl">
              <div
                className=" text-lg text-center md:text-xl font-semibold"
                style={{ fontFamily: "Montserrat" }}
              >
                📖 Practical Learning
              </div>
              <p
                className="text-gray-600  text-sm md:text-sm text-center"
                style={{ fontFamily: "Montserrat" }}
              >
               Go beyond textbooks with hands-on projects, real-world experience and industry exposure. 
              </p>
            </motion.div>

            <motion.div  whileHover={{ scale: 1.1 }} className="bg-peterriver w-full h-[200px] flex flex-col justify-center items-center p-4 md:p-6 shadow-lg rounded-xl">
              <div
                className=" text-lg text-center md:text-xl font-semibold"
                style={{ fontFamily: "Montserrat" }}
              >
                🏆 Global Credibility
              </div>
              <p
                className="text-gray-600 text-sm md:text-sm text-center"
                style={{ fontFamily: "Montserrat" }}
              >
                Boost your career with certifications from NASA, MIT, and Harvard enhance your global credibility
              </p>
            </motion.div>

            {/* Exclusive Access Card (Mobile Version) */}
            <motion.div  whileHover={{ scale: 1.1 }} className="bg-peterriver w-full h-[200px] flex md:hidden flex-col justify-center items-center p-4 shadow-lg rounded-xl border border-gray-200 text-center">
              <div
                className=" text-lg text-center md:text-xl font-semibold"
                style={{ fontFamily: "Montserrat" }}
              >
                🎓 Exclusive Access
              </div>
              <p
                className="text-gray-600 mt-2 text-sm md:text-sm"
                style={{ fontFamily: "Montserrat" }}
              >
               Join an exclusive group of selected Simplifying Skills students on this prestigious journey.
              </p>
            </motion.div>
          </div>
        </div>

        {/* Right Side - Character Image */}
        <div className="w-full md:w-1/2 flex justify-center items-center mb-10 md:mb-0 ml-[-40px]">
          <img
            src={character}
            alt="Character"
            className="w-44 xs:w-52 md:w-72 object-contain"
          />
        </div>
      </div>

      {/* <div><img
        src={design24}
        className="w-[50%] mt-5   mx-auto"
        alt="Design"
      /></div> */}

      {/* 
      <img
        src={design24}
        className="w-[70%] xs:h-[210px] sm:h-[240px] lg:-mt-[140px] md:-mt-[160px] xs:-mt-[110px] mx-auto"
        alt="Design"
      />

      <div className="text-[#facc15] text-3xl md:text-4xl font-bold mt-2 mb-5">
        Why This Exposure Matters?
      </div> */}

      {/* <div
        className="w-full h-auto md:h-[800px] bg-cover bg-top bg-no-repeat flex flex-col justify-center items-center lg:mt-30  xs:mt-20 mb-5 px-4"
        style={{ backgroundImage: "url('/bluebg.png')" }}
      >
       
        <div className="max-w-[1200px] w-full text-center relative">
         
          <img
            src={design24}
            className="w-[70%] xs:h-[210px] sm:h-[240px] lg:-mt-[140px] md:-mt-[160px] xs:-mt-[110px] mx-auto"
            alt="Design"
          />

        
          <div className="text-[#facc15] text-3xl md:text-4xl font-bold mt-2 mb-5">
            Why This Exposure Matters?
          </div>

          
          <div className="grid grid-cols-1 md:grid-cols-2 gap-5 lg:gap-10">
          
            <motion.div whileHover={{scale:1.1}} className="bg-[#74b9ff] opacity-80  rounded-2xl flex items-center">
              <img
                src={image20}
                className="w-[100px] md:w-[140px] mr-4"
                alt="Insight"
              />
              <p className="text-white font-medium text-left">
                Learn from the best and gain insights from some of the brightest
                minds in science, technology, and leadership.
              </p>
            </motion.div>

           
            <motion.div whileHover={{scale:1.1}} className="bg-[#74b9ff] opacity-80  rounded-2xl flex items-center">
              <img
                src={image24}
                className="w-[100px] md:w-[140px] mr-4"
                alt="Innovation"
              />
              <p className="text-white font-medium text-left">
                Expand your horizons by exploring cutting-edge research and
                breakthrough innovations.
              </p>
            </motion.div>

            
            <motion.div whileHover={{scale:1.1}} className="bg-[#74b9ff] opacity-80  rounded-2xl flex items-center">
              <img
                src={image22}
                className="w-[100px] md:w-[130px] mr-4"
                alt="Learning"
              />
              <p className="text-white font-medium text-left">
                Learn from the best and gain insights from some of the brightest
                minds in science, technology, and leadership.
              </p>
            </motion.div>

           
            <motion.div whileHover={{scale:1.1}} className="bg-[#74b9ff] opacity-80  rounded-2xl flex items-center">
              <img
                src={image23}
                className="w-[100px] md:w-[140px] mr-4"
                alt="Certifications"
              />
              <p className="text-white font-medium text-left">
                Boost your career with certifications from NASA, MIT, and
                Harvard, enhancing your global credibility.
              </p>
            </motion.div>

          
            <motion.div whileHover={{scale:1.1}} className="flex justify-center col-span-1 md:col-span-2">
              <div className="w-[280px] xs:w-[590px] bg-[#74b9ff] opacity-80 p-5 rounded-2xl flex items-center">
                <img
                  src={image21}
                  className="w-[70px] md:w-[100px] mr-4"
                  alt="Exclusive"
                />
                <p className="text-white font-medium text-left">
                  Be part of an exclusive group  from
                  Simplifying Skills get access to this prestigious journey.
                </p>
              </div>
            </motion.div>
          </div>
        </div>
      </div> */}
    </div>
  );
}
