import { useState, useEffect } from "react";

export function useTypewriter(text, speed = 50, startTyping, delay = 0) {
    const [displayText, setDisplayText] = useState("");

    useEffect(() => {
        if (!startTyping) return; 

        let i = 0;
        setDisplayText(""); 

        const timeout = setTimeout(() => {
            const interval = setInterval(() => {
                if (i < text.length) {
                    setDisplayText((prev) => prev + text[i]);
                    i++;
                } else {
                    clearInterval(interval);
                }
            }, speed);
            return () => clearInterval(interval);
        }, delay);

        return () => clearTimeout(timeout);
    }, [text, startTyping]);

    return displayText;
}
