import c1 from "../../../images/cll1.jpeg";
import c2 from "../../../images/ccg2.jpeg";
import c3 from "../../../images/cllg3.jpeg";
import c4 from "../../../images/cll4.jpeg";
import c5 from "../../../images/cllg5.jpeg";
import c6 from "../../../images/cllg6.jpeg";
import c7 from "../../../images/cllg7.png";
import cllglogo from "../../../images/cllglogo1.png";
import cllglogo2 from "../../../images/cllglogo2.png";
import cllglogo3 from "../../../images/cllglogo3.png";
import cllglogo4 from "../../../images/cllglogo4.png";

import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";

const Gallery = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const images = [c1, c2, c3, c4, c5, c6, c7, cllglogo, cllglogo2, cllglogo3, cllglogo4];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  // Function to get the current 4 images to display
  const getCurrentImages = () => {
    const imagesToShow = [];
    for (let i = 0; i < 4; i++) {
      const index = (currentIndex + i) % images.length;
      imagesToShow.push(images[index]);
    }
    return imagesToShow;
  };

  return (
    <div className="bg-white py-10 lg:mt-[10%] xl:mt-0 px-5">
      <motion.h2 whileHover={{scale:1.3}} className="text-center text-3xl font-bold text-gray-800 mb-6">
        PROUD <span className="text-orange-500">CUSTOMERS</span>
      </motion.h2 >
      <div className="flex justify-center overflow-hidden">
        <motion.div
          key={currentIndex}
          initial={{ opacity: 0, x: 50 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -50 }}
          transition={{ duration: 0.5 }}
          className="grid grid-cols-2 md:grid-cols-4 gap-4"
        >
          {getCurrentImages().map((img, index) => (
            <img
              key={index}
              src={img}
              alt={`Customer ${index + 1}`}
              className="w-40 h-40 object-contain mx-auto shadow-lg rounded-lg bg-white p-2"
            />
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Gallery;