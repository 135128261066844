import React from "react";
import { motion } from "framer-motion";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const testimonials = [
  {
    name: "Gunish Sukhija",
    handle: "Manager at Metro",
    review:
      "Best part I like here is the trainers are profound with their knowledge and have a robust approach. I really like Simplifying skills as they understand the challenges of trainees.. and then go ahead accordingly.",
    rating: 5,
  },
  {
    name: "Anushi Shrivastava",
    handle: "Account Manager at Iplace",
    review:
      "I started my career with Simplifying Skills. Got good exposure. The company has a women-friendly environment. It's a great place to grow. It was good working with this organisation.",
    rating: 5,
  },
  {
    name: "Chaitanya Thapa",
    handle: "Student",
    review:
      "Great opportunity for people to get their dream job ,looks good when you hear something like that.",
    rating: 5,
  },
  {
    name: "Sanjay shilal",
    handle: "Manager at AVL India",
    review:
      "Good and very reasonable platform to acquire corporate skills at your own pace.",
    rating: 5,
  },
  {
    name: "Deepali Arora",
    handle: "CRM Head at Cred",
    review:
      "Good environment with beautiful ambience. Staff is so supportive and helpful.",
    rating: 5,
  },
  {
    name: "Shivangi Gaur",
    handle: "HR at Anonymous",
    review:
      "Simplifying skills is the best place to enhance your skills and get the best start in your career path.",
    rating: 5,
  },
  {
    name: "Aditya Sharma",
    handle: "Salesforce developer at Hitech Pro",
    review:
      "It was a good institute for training skills in different aspects - salesforce, data science, full stack.",
    rating: 5,
  },
  {
    name: "Kawish Daniyal",
    handle: "Data scientist at Wipro",
    review:
      "Great place to learn,work and grow. Faculties were also quite experienced in their fields.",
    rating: 5,
  },
  {
    name: "Omkar Malwadkar",
    handle: "Data Scientist at Deloitte",
    review:
      "Good place to learn new technologies like Hadoop, Data Analytics and many more with 100% placement.",
    rating: 5,
  },
  {
    name: "Rashmi Tewari",
    handle: "Software developer at TCS",
    review:
      "Excellent trainers. Always approachable. Great career counselling. Recommend simplifying skills for Data science courses!!!",
    rating: 5,
  },
];

const TestimonialsSection = () => {
  // Settings for the slider
  const settings = {

    infinite: true,
    arrows: false,
    speed: 800,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      className="py-16  w-screen h-[430px] text-center"
      style={{
        backgroundImage:
          "radial-gradient(circle at left, #087def 1%, transparent 20%)",
        backgroundColor: "white",
      }}
    >
      <motion.div
        whileHover={{ scale: 1.3 }}
        className="text-2xl lg:text-4xl mb-8 lg:mb-16 font-extrabold text-gray-900"
      >
        CUSTOMER<span className="text-orange-500 ml-3">TESTIMONIALS</span>
      </motion.div>

      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <div key={index} className="px-2">
            <TestimonialCard data={testimonial} />
          </div>
        ))}
      </Slider>
    </div>
  );
};

const TestimonialCard = ({ data }) => {
  return (
    <motion.div
      whileHover={{
        scale: 1.05,
        boxShadow: "0px 10px 20px rgba(0,0,0,0.2)",
      }}
      transition={{ duration: 0.3 }}
      className="w-80  h-auto bg-white rounded-lg overflow-hidden shadow-md mx-auto"
    >
      <div className="p-6 bg-gray-100">
        <div className="flex justify-center ">
          {Array(data.rating)
            .fill()
            .map((_, i) => (
              <span key={i} className="text-yellow-400 text-xl">
                ★
              </span>
            ))}
        </div>
        <p className=" h-[13vh] flex items-center text-gray-600 text-sm">{data.review}</p>
      </div>

      <div className="bg-blue-500 h-[65px] justify-center text-white flex flex-col">
        <h4 className="font-bold text-xs uppercase tracking-wide text-center">
          {data.name}
        </h4>
        <p className="opacity-80 text-xs text-center">{data.handle}</p>
      </div>
    </motion.div>
  );
};

export default TestimonialsSection;