import React, { useRef, useEffect, useState } from "react";
import design2 from "../../../images/design2.svg";
import image1 from "../../../images/image1.svg";
import design3 from "../../../images/design3.svg";
import design4 from "../../../images/design4.svg";
import image2 from "../../../images/image2.png";
import design5 from "../../../images/design5.svg";
import image3 from "../../../images/image3.png";
import image4 from "../../../images/image4.png";
import design6 from "../../../images/design6.svg";
import image5 from "../../../images/image5.png";
import design7 from "../../../images/design7.svg";
import image6 from "../../../images/image6.png";
import design8 from "../../../images/design8.svg";
import image7 from "../../../images/image7.png";
import design9 from "../../../images/design9.svg";
import image8 from "../../../images/image8.png";
import design10 from "../../../images/design10.svg";
import image9 from "../../../images/image9.png";
import design11 from "../../../images/design11.svg";
import image10 from "../../../images/image10.png";
import design12 from "../../../images/design10.svg";
import image11 from "../../../images/image11.png";
import design13 from "../../../images/design13.svg";
import image12 from "../../../images/image12.png";
import design14 from "../../../images/design14.svg";
import image13 from "../../../images/image13.png";

const courses = [
  {
    id: 1,
    title: "Entrepreneurship and Leadership",
    description:
      "Explore how great ideas become thriving ventures and learn the skills to lead with confidence. Master strategic planning, effective communication, and teamwork to shape the future.",
    image: design2,
    image2: image1,
  },
  {
    id: 2,
    title: "Artificial Intelligence",
    description:
      "Discover how AI learns, thinks, and transforms industries worldwide. From chatbots to self-driving cars, gain the skills to innovate and build smart solutions.",
    image: design3,
    image2: image2,
  },
  {
    id: 3,
    title: "Financial Literacy",
    description:
      "Learn budgeting, saving, investing, and debt management to take charge of your finances. Acquire real-world money skills that empower you to achieve your goals.",
    image: design4,
    image2: image3,
  },
  {
    id: 4,
    title: "Robotics",
    description:
      "Explore how robots move, sense, and interact, from mechanical design to programming. Discover real-world applications, fueling your passion for innovation and problem-solving.",
    image: design5,
    image2: image4,
  },
  {
    id: 5,
    title: "Critical Thinking and Problem Solving",
    description:
      "Sharpen your analytical mindset to tackle challenges with clarity and creativity. Use proven strategies, dynamic exercises, and logical reasoning to solve problems like a pro.",
    image: design6,
    image2: image5,
  },
  {
    id: 6,
    title: "Ethical and Global Citizenship",
    description:
      "Discover how ethical choices and active citizenship can shape communities and the world. Explore human rights, sustainability, and leadership to drive meaningful change.",
    image: design7,
    image2: image6,
  },
  {
    id: 7,
    title: "Innovation and Creativity",
    description:
      "Unlock your creative potential and turn bold ideas into real solutions. Embrace imaginative thinking, experiment fearlessly, and innovate for a brighter future.",
    image: design8,
    image2: image7,
  },
  {
    id: 8,
    title: "Coding",
    description:
      "Learn programming fundamentals to build apps, websites, or games from scratch. Sharpen your logic, open career doors, and thrive in our tech-driven world.",
    image: design9,
    image2: image8,
  },
  {
    id: 9,
    title: "Communication Skills",
    description:
      "Master verbal and non-verbal expressions to speak with clarity and confidence. Build essential communication skills for presentations skills for presentations, teamwork, and social success.",
    image: design10,
    image2: image9,
  },
  {
    id: 10,
    title: "Emotional Intelligence",
    description:
      "Build self-awareness, empathy and professional growth. Learn practical strategies to manage emotions and faster stronger relationships.",
    image: design11,
    image2: image10,
  },
  {
    id: 11,
    title: "Environmental Literacy",
    description:
      "Learn substainability, conversation, and real-world solutions to protect our planet. Become a responsible global citizen by understanding and addressing key environmental issues.",
    image: design12,
    image2: image11,
  },
  {
    id: 12,
    title: "Data Literacy",
    description:
      "Master the basics of data interpretation for smarter decisions. Gain practical skills to navigate our information-rich world and spark innovation.",
    image: design13,
    image2: image12,
  },
  {
    id: 13,
    title: "Digital Literacy and Information Tech",
    description:
      "Master esential tech skills-from safe browsing to effecient communication-to excel in a digital world. Learn the basics, boost productivity, and stay ahead in tech-driven environments.",
    image: design14,
    image2: image13,
  },
];

const backgroundColors = [
  "#facc15",
  "rgb(95, 154, 242)",
  "rgb(215, 253, 140)",
  "rgb(197, 234, 255)",
  "rgb(242, 61, 61)",
  "rgb(115, 95, 242)",
  "rgb(245, 190, 78)",
  "rgb(0, 191, 255)",
  "rgb(242, 19, 123)",
  "rgb(252, 210, 255)",
  "rgb(215, 253, 140)",
  "rgb(95, 154, 242)",
  "rgb(115, 95, 242)",
];
const titleColors = [
  "#000",
  "#fff",
  "#000",
  "#000",
  "#facc15",
  "#facc15",
  "rgb(76, 43, 0)",
  "#fff",
  "#fff",
  "rgb(159, 21, 174)",
  "rgb(10, 75, 33)",
  "#facc15",
  "#facc15",
];

const descriptionColors = [
  "#000",
  "#fff",
  "#000",
  "#000",
  "#fff",
  "#fff",
  "#000",
  "#fff",
  "#fff",
  "#000",
  "#000",
  "#fff",
];

export default function Slider() {
  const containerRef = useRef(null);
  
  const scrollLeft = () => {
    const isMobile = window.innerWidth <= 768;
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: isMobile ? -320 : -700,
        behavior: "smooth",
      });
    }
  };

  const scrollRight = () => {
    const isMobile = window.innerWidth <= 768;
    if (containerRef.current) {
      containerRef.current.scrollBy({
        left: isMobile ? 320 : 700,
        behavior: "smooth",
      });
    }
  };

  // useEffect(() => {
  //   // Check screen width
  //   if (window.innerWidth < 768) return; // Stop auto-scrolling on mobile
  
  //   const interval = setInterval(() => {
  //     if (containerRef.current) {
  //       const { scrollLeft, scrollWidth, clientWidth } = containerRef.current;
  //       const maxScrollLeft = scrollWidth - clientWidth;
  
  //       if (scrollLeft >= maxScrollLeft - 10) {
  //         containerRef.current.scrollTo({
  //           left: 0,
  //           behavior: "auto",
  //         });
  //       } else {
  //         containerRef.current.scrollBy({
  //           left: 800,
  //           behavior: "smooth",
  //         });
  //       }
  //     }
  //   }, 2000);
  
  //   return () => clearInterval(interval);
  // }, []);
  


  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginTop: "30px",
        marginRight: "75px",
        marginLeft: "65px",
      }}
    >
      <div
        ref={containerRef}
        style={{
          display: "flex",
          overflowX: "auto",
          width: "87%",
          maxWidth: "100vw",
          scrollBehavior: "smooth",
          paddingBottom: "20px",
          scrollbarWidth: "none",
          msOverflowStyle: "none",
        }}
      >
        {" "}
        
        {courses.map((course, index) => (
          <div
            key={course.id}
            style={{
              flex: "0 0 auto",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              width: "310px",
              height: "300px",
              backgroundColor: "#facc15",
              padding: "20px",
              borderRadius: "10px",
              backgroundColor:
                backgroundColors[index % backgroundColors.length],
              justifyContent: "center",
              marginRight: "30px",
            }}
            onMouseEnter={(e) => {
              e.currentTarget.style.boxShadow =
                "8px 20px 10px rgba(0, 0, 0, 0.4)";
            }}
            onMouseLeave={(e) => {
              e.currentTarget.style.boxShadow = "";
            }}
          >
           
            <h2
              style={{
                fontSize: "24px",
                fontWeight: "bold",
                color: "rgb(67, 49, 8)",
                textAlign: "center",
                color: titleColors[index % titleColors.length],
              }}
            >
              {course.title}
            </h2>
            <div
              style={{ display: "flex", width: "100%", alignItems: "center" }}
            >
              <p
                style={{
                  fontSize: "14px",
                  lineHeight: "1.2",
                  marginTop: "10px",
                  padding: "10px",
                  fontWeight: "medium",
                  color: descriptionColors[index % descriptionColors.length],
                }}
              >
                {course.description}
              </p>
              <div
                style={{
                  position: "relative",
                  width: "530px",
                  height: "auto",
                }}
              >
                <img
                  src={course.image}
                  alt={course.title}
                  style={{
                    width: "100%",
                    height: "auto",
                  }}
                />
                <img
                  src={course.image2}
                  alt="Overlay"
                  style={{
                    position: "absolute",
                    top: "8px",
                    left: "0",
                    width: "110px",
                    height: "auto",
                  }}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
          gap: "20px",
        }}
      >
        <button
          onClick={scrollLeft}
          style={{
            padding: "10px 20px",
            fontSize: "30px",
            color: "#fff",
            backgroundColor: "rgb(15, 119, 230)",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onMouseEnter={(e) =>
            (e.target.style.backgroundColor = "rgb(250, 124, 7)")
          }
          onMouseLeave={(e) =>
            (e.target.style.backgroundColor = "rgb(15, 119, 230)")
          }
        >
          &larr;
        </button>
        <button
          onClick={scrollRight}
          style={{
            padding: "10px 20px",
            fontSize: "30px",
            color: "#fff",
            backgroundColor: "rgb(15, 119, 230)",
            border: "none",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onMouseEnter={(e) =>
            (e.target.style.backgroundColor = "rgb(250, 124, 7)")
          }
          onMouseLeave={(e) =>
            (e.target.style.backgroundColor = "rgb(15, 119, 230)")
          }
        >
          &rarr;
        </button>
      </div>
    </div>
  );
}