import left from "../../../images/left.png";
import right from "../../../images/right.png";
import center from "../../../images/center.png";
import { motion } from "framer-motion"; // Ensure you're using the correct import for motion
import { useNavigate } from 'react-router-dom';
export default function Whatwedo() {


  const navigate = useNavigate();
 
  const navigateToAssessment = () => {
    navigate('/assessment'); // Navigates to the assessment route
  };
  
  return (
    <div>
      <div
        className="flex w-full h-auto md:h-screen  justify-center flex-col"
        
      >
        {/* Text Container */}
        <div className="w-full flex flex-col justify-center px-4 md:px-0">
          {/* Heading */}
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="flex justify-center text-2xl md:text-4xl font-semibold"
          >
            <span>What</span>
            <span className="ml-2 font-bold text-[#3479FF]">We Do</span>
          </motion.div>

          {/* Subheading */}
          <motion.div
            whileHover={{ scale: 1.3 }}
            className="flex justify-center text-center text-xl md:text-3xl font-semibold text-[#F8A401] text-shadow-md mt-2 md:mt-0"
          >
            From Classrooms to Boardrooms – Shaping Tomorrow’s Leaders
          </motion.div>

          {/* Description */}
          <div className="flex justify-center mt-4 font-normal">
            <div className="w-full md:w-[70%] text-center text-black text-sm md:text-base">
              At Simplifying Skills, we bridge the gap between education and
              real-world success by offering dynamic, future-focused learning
              experiences. Through immersive technology, hands-on training, and
              interactive methodologies, we help students develop critical
              thinking, creativity, and leadership skills. Our programs are
              designed by experts, ensuring students are equipped to excel in
              tomorrow’s workforce and make a meaningful impact on the world. We
              don’t just teach—we inspire, challenge, and equip learners with
              the skills they need to thrive.
            </div>
          </div>
        </div>

        {/* Image Container */}
        <div className="flex flex-col xl:h-[60vh] mt-5 w-screen xs:flex-row sm:flex-row md:flex-row justify-center items-center gap-5 h-auto md:h-auto ">
  {/* Left Image */}
  <div className="md:-mr-20 xs:-mr-10 z-10"> {/* Moves the left image slightly over the center */}
    <img
      src={left}
      alt="Left"
      className="rounded-full xl:h-[40vh] xs:h-[12vh]  md:h-[20vh] border-[5px] border-[#FFDC4F]"
    />
  </div>

  {/* Center Image */}
  <div className="relative z-0">
    <img
      src={center}
      alt="Center"
      className="rounded-full xs:h-[20vh] xl:h-[55vh]  md:h-[30vh]  border-[5px] border-[#4066FE]"
    />
  </div>

  {/* Right Image */}
  <div className="md:-ml-20 xs:-ml-10 z-10"> {/* Moves the right image slightly over the center */}
    <img
      src={right}
      alt="Right"
      className=" rounded-full xl:h-[40vh] md:h-[20vh]  xs:h-[12vh]  border-[5px] border-[#FFDC4F]"
    />
  </div>
</div>
      </div>
    </div>
  );
}