import { motion } from "motion/react";
import star1 from "../../../images/Star 1.png";
import a from "../../../images/a++.png";
import trophy from "../../../images/trophy.png";
import centerimage from "../../../images/centerimage.png";
export default function BeyondClassroom() {
  const handleWhatsAppRedirect = () => {
    const phoneNumber = "917798283335";
    const message = encodeURIComponent(
      "Hello, I'm interested in learning more about your services!"
    );
    const whatsappURL = `https://wa.me/${phoneNumber}?text=${message}`;

    window.open(whatsappURL, "_blank");
  };
  return (
    <div className="relative flex flex-col items-center justify-center pt-10">
      <div
        className="flex justify-center font-bold text-7xl md:text-6xl sm:text-4xl xs:text-[26px] font-Luckiest Guy tracking-widest"
        style={{
          color: "#FEBF23",
          fontFamily: "Luckiest Guy",
          textShadow: "2px 2px 6px rgba(0, 0, 0, 0.4)"
        }}
      >
        BeYOnD THe CLASSRooM
      </div>
      <div className="absolute right-[-27px] top-1 transform translate-y-[-50%] w-32 h-16 border-[12px] border-yellow-300 border-b-transparent rounded-t-full opacity-50 -rotate-90"></div>

      <div className="relative flex flex-col items-center w-full mt-10">
        {/* Top Box */}
        <motion.div
          whileHover={{ scale: 1.1 }}
          className="relative flex flex-col items-center p-4 bg-white shadow-lg rounded-lg"
        >
          <div className="absolute -top-4 px-3 py-1 bg-purple-500 text-white text-sm font-bold rounded-full">
            01
          </div>
          <p className="text-center text-gray-600 text-sm w-[300px]">
            Explore the world through museum visits, nature excursions, and
            virtual experiences.
          </p>
        </motion.div>

        
        <div className="relative w-[300px] md:w-[400px] hidden sm:block z-10">
          <img
            src={centerimage}
            alt="Center Image"
            className="w-full rounded-lg shadow-lg"
          />

          
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="absolute top-1/4 left-[-40px] md:left-[-50%] p-4 bg-white shadow-lg rounded-lg hidden sm:flex"
          >
            <div className="absolute -top-4 px-3 py-1 bg-purple-500 text-white text-sm font-bold rounded-full">
              02
            </div>
            <p className="text-center text-gray-600 text-sm w-[200px]">
              Gain insights and inspiration from industry experts, creators, and
              thought leaders.
            </p>
          </motion.div>

         
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="absolute top-1/4 right-[-40px] md:right-[-35%] p-4 bg-white shadow-lg rounded-lg hidden sm:flex"
          >
            <div className="absolute -top-4 px-3 py-1 bg-purple-500 text-white text-sm font-bold rounded-full">
              03
            </div>
            <p className="text-center text-gray-600 text-sm w-[200px]">
              Showcase creativity through interactive presentations and hands-on
              project exhibitions.
            </p>
          </motion.div>
        </div>

       
        <div className="flex flex-col items-center w-full mt-6 sm:hidden">
         
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="relative flex flex-col items-center p-4 bg-white shadow-lg rounded-lg"
          >
            <div className="absolute -top-4 px-3 py-1 bg-purple-500 text-white text-sm font-bold rounded-full">
              02
            </div>
            <p className="text-center text-gray-600 text-sm w-[300px]">
              Gain insights and inspiration from industry experts, creators, and
              thought leaders.
            </p>
          </motion.div>

         
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="relative flex flex-col items-center p-4 bg-white shadow-lg rounded-lg mt-6"
          >
            <div className="absolute -top-4 px-3 py-1 bg-purple-500 text-white text-sm font-bold rounded-full">
              03
            </div>
            <p className="text-center text-gray-600 text-sm w-[300px]">
              Showcase creativity through interactive presentations and hands-on
              project exhibitions.
            </p>
          </motion.div>
        </div>
      </div>

      <div className="relative w-full h-screen flex justify-center items-end bg-white mt-">
        <div className="absolute top-[-35%] left-1/4 w-96 h-96 z-0 -rotate-45 hidden lg:block">
          <svg width="100%" height="100%" viewBox="0 0 200 200">
            <path
              d="M 10,100 A 90,90 0 0,1 190,100"
              fill="none"
              stroke="rgba(165, 143, 255, 0.3)"
              strokeWidth="15"
            />
          </svg>
        </div>

        <div className=" hidden sm:block absolute top-[-15%] left-[22%] text-purple-400 text-xl z-20">
          <img src={star1} />
        </div>

        <div
          className="w-full h-screen relative bg-cover bg-top bg-no-repeat flex flex-col justify-center items-center"
          style={{ backgroundImage: "url('/cloudbluebg.png')" }}
        >
          <div className="absolute top-21 right-10 lg:block hidden">
            <img src={a} className="w-[120px]" />
          </div>

          <div
            className="flex flex-col items-center mt-10 text-white text-7xl"
            style={{
              fontFamily: "Luckiest Guy",
              textShadow: "4px 4px 6px rgba(0, 0, 0, 0.4)",
            }}
          >
            <span className="text-7xl font-bold text-left">JOIN</span>
            <h2 className="text-3xl font-semibold text-yellow-400 text-center">
              The NextGen Learning Revolution
            </h2>

            <p className="text-[18px] max-w-[700px] mx-auto leading-loose text-center">
              Empower your child with immersive experiences, real-world
              adventures, and boundless creativity to shape a future full of
              possibilities.
            </p>

            <div
              className="sm:w-full xs:w-[300px] h-[300px] bg-cover rounded-xl bg-center flex flex-col justify-center mt-6"
              style={{ backgroundImage: "url('/imageblue.png')" }} // Correctly setting background
            >
              <div className="ml-8">
                <h2
                  className="text-white text-[32px] font-bold tracking-widest"
                  style={{
                    fontFamily: "Luckiest Guy",
                  }}
                >
                  START YOUR LEARNING <br /> JOURNEY TODAY
                </h2>

                <div
                  onClick={handleWhatsAppRedirect}
                  className="mt-2 cursor-pointer bg-customYellow w-[130px] h-[30px] text-black rounded-xl text-sm flex justify-center items-center hover:bg-yellow-500 font-bold"
                >
                  Connect With Us
                </div>
              </div>
            </div>
          </div>
          <div className="absolute bottom-8 left-10 hidden lg:block">
            <img src={trophy} className="w-[150px]" />
          </div>
        </div>
      </div>
    </div>
  );
}
