import { useState } from "react";
import Scroll1 from "./Scroll1";
import MainModels from "./MainModels";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import barmodel from "../../../../images/barmodel.png";
import Brain from "../../../../images/brain.png";
import riasecmodel from "../../../../images/riasecmodel.png";
import coremodel from "../../../../images/coremodel.png";
import aptitudetest from "../../../../images/aptitudetest.png";

export default function CreateHomepageFirst() {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.down("sm"));
  const [scrollToSlide, setScrollToSlide] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);

  var jobData = [{ companyid: 1, modelname: "Psychometric Traits", headline: "Understanding the Science of Personality",lowerline1:'The OCEAN Model, also known as the Big Five Personality Traits, is a globally ,recognized framework for understanding personality.',lowerline2:'It evaluates five key traits—Openness, Conscientiousness,Extraversion, Agreeableness, and Neuroticism—helping you discover how your personality influences leadership, communication, and career growth.',
    lowerline3: "  Enhance self-awareness and decision-making.\n  Help you identify careers that align with your strengths.\n  Improve adaptability, teamwork, and leadership skills.",logo:Brain

  },
  { companyid: 2, modelname: "Bar-on Model", headline: "Building Social & Emotional Intelligence",lowerline1:'Developed by Dr. Reuven Bar-On, the EQ-i Model measures Social and Emotional Intelligence (SEI)—the foundation of personal and professional success.',lowerline2:'Strengthen leadership, teamwork, and communication (skills)" at the end.',
    lowerline3: "   Enhance emotional resilience and adaptability.\n\u00a0Strengthen leadership, teamwork, and communication.\u00a0\nImprove decision-making and interpersonal effectiveness.\u00a0",logo:barmodel

  },
  { companyid: 3, modelname: "RIASEC Model", headline: "Aligning Interests with Careers\u00a0",lowerline1:'The RIASEC Model, developed by psychologist John Holland, categorizes careers into six personality-driven domains—Realistic, Investigative, Artistic, Social, Enterprising, and Conventional. By aligning your interests with the right career paths.',lowerline2:'This model helps you make informed choices that match your strengths and passions.\u00a0',
    lowerline3: " Identify career fields best suited to your personality and skills.\u00a0\n  Reduce uncertainty in career selection with a structured approach. \nHelp you develop industry-relevant skills for long-term success.\u00a0",logo:riasecmodel

  },
  { companyid: 4, modelname: "CORE Model", headline: "Mastering Resilience & Future Readiness",lowerline1:'Developed by Dr. Paul G. Stoltz, the Adversity Quotient measures how well individuals respond to challenges, setbacks, and obstacles. ',lowerline2:'The CORE Model—Control, Ownership, Reach, and Endurance—assesses resilience and the ability to thrive in adversity, a key trait for personal and professional growth.\u00a0',
    lowerline3: "  Enhance your ability to navigate challenges with confidence.\u00a0\n Build resilience, persistence, and problem-solving skills.\u00a0\n     Empower you to turn setbacks into opportunities for success.\u00a0",logo:coremodel

  },
  { companyid: 5, modelname: "Differential Aptitude Test", headline: "Assessing Key Aptitude Traits",lowerline1:'Developed in 1947 by Bennett, Seashore, and Wesman, the widely popular Differential Aptitude Test (DAT) helps you assess key aptitude traits like verbal reasoning, numerical ability, abstract thinking, spatial relations, and mechanical reasoning.\u00a0',lowerline2:'',
    lowerline3: " Provide deep insights into your cognitive strengths.\n Help you align your skills with the right career path. \n   Enable informed academic and professional decisions.\u00a0",logo:aptitudetest
  },
  ]

  var model = [
    { mainmodelname: "OCEAN Model" },
    { mainmodelname: "Bar-On Model" },
    { mainmodelname: "RIASEC Model" },
    { mainmodelname: "CORE Formula" },
    { mainmodelname: "DAT Framework" }
  ];

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        overflowX: "hidden",
        width: "100%",
        height: "auto",
        maxWidth: "100vw",
      }}
    >
      <div
        style={{
          backgroundColor: "#E8E9FA",
          width: "100%",
          maxWidth: "100vw",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ margin: "20px 0", width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                fontSize: matches ? 20 : 30,
                fontWeight: "bold",
                textAlign: "center",
              }}
            >
              Powered by globally recognized models:
            </div>
            {!matches && (
              <MainModels
                data={model}
                onBoxClick={scrollToSlide}
                activeIndex={activeIndex}
              />
            )}
          </div>
          <div style={{ width: "100%", maxWidth:'100vw' }}>
            <Scroll1
              data={jobData}
              setScrollFunction={setScrollToSlide}
              setActiveIndex={setActiveIndex}
              autoHighlight={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

