import blog12 from "../../../images/blogLast.jpg";
import React from "react";
import { motion } from "motion/react";
import { useNavigate } from "react-router-dom";

export default function SectionStory({ stories }) {
  // Add your backend base URL
  const BASE_URL = "https://simplifyingskills.com"; // Adjust if your backend runs on a different URL

  // Process stories to include full image URLs
  const processedStories = stories.map(story => ({
    ...story,
    image: story.image && typeof story.image === 'string' && story.image.startsWith('/') 
      ? `${BASE_URL}${story.image}` 
      : story.image || `${BASE_URL}default-image.jpg` // Use a default image if null
  }));

  const staticOne = {
    storyName: "And many more exciting stories",
    image: blog12,
    description: "Stories That Entertain, Educate & Inspire!",
  }

  const navigate = useNavigate();

  const handleStory = (story) => {
    navigate("/describestory", {
      state: { 
        name: story.storyName, 
        img: story.image, 
        full: story.fullStory 
      },
    });
  };

  return (
    <div className="w-full flex justify-center pt-8 px-4 sm:px-6 lg:px-8">
      <div className="w-full max-w-6xl">
        {/* Mobile view (xs, sm, md) - 1 card per row */}
        <div className="flex flex-col gap-6 md:hidden">
          {[...processedStories, staticOne].map((story, index) => (
            <StoryCard key={index} story={story} handleStory={handleStory} />
          ))}
        </div>

        {/* Desktop view (lg, xl) - 2 cards per row */}
        <div className="hidden md:grid md:grid-cols-1 lg:grid-cols-2 gap-6">
          {[...processedStories, staticOne].map((story, index) => (
            <StoryCard key={index} story={story} handleStory={handleStory} />
          ))}
        </div>
      </div>
    </div>
  );
}

function StoryCard({ story, handleStory }) {
  return (
    <motion.div
      whileHover={{ scale: 1.02 }}
      whileTap={{ scale: 0.98 }}
      className="w-full bg-[#e8f7eb] rounded-xl overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300 cursor-pointer flex flex-row h-[180px]"
    >
      {/* Image container - fixed size covering full box */}
      <div className="w-[35%] h-full flex-shrink-0 bg-[#85929e] overflow-hidden rounded-3xl">
        <img
          src={story.image}
          className="w-full h-full object-cover"
          alt={story.storyName}
          onError={(e) => {
            e.target.onerror = null; // Prevent infinite loop
            e.target.src = "https://via.placeholder.com/150"; // Fallback image
          }}
        />
      </div>

      {/* Content container */}
      <div
        className="flex-1 p-4 flex flex-col justify-between"
        style={{ fontFamily: "Roboto" }}
      >
        <div>
          <h3 className="text-lg font-semibold mb-2 line-clamp-2">
            {story.storyName}
          </h3>
          <p className="text-sm text-gray-600 mb-2 line-clamp-3">
            {story.description}
          </p>
        </div>

        <button
          onClick={() => handleStory(story)}
          className="self-start bg-blue-700 hover:bg-blue-800 text-white text-xs px-3 py-1.5 rounded-full flex items-center transition-colors duration-300 mt-2"
        >
          Read More
          <span className="ml-1 text-sm">&rarr;</span>
        </button>
      </div>
    </motion.div>
  );
}