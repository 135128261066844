import { useState } from "react";
import { Paper, Typography } from "@mui/material";

export default function MainModels({ data, onBoxClick, activeIndex }) {
  const [hoverIndex, setHoverIndex] = useState(null); // Track hover state

  return (
    <div style={{ display: "flex", justifyContent: "center", flexWrap: "wrap", maxWidth: "100%" }}>
      {data.map((item, index) => {
        // Check if this button should be highlighted (either hover or activeIndex)
        const isHighlighted = hoverIndex === index || (hoverIndex === null && activeIndex === index);

        return (
          <Paper
            key={index}
            elevation={6}
            onMouseEnter={() => setHoverIndex(index)} // Track mouse hover
            onMouseLeave={() => setHoverIndex(null)} // Reset when mouse leaves
            onClick={() => {
              if (onBoxClick) {
                onBoxClick(index);
              }
            }}
            sx={{
              background: isHighlighted 
                ? "linear-gradient(180deg, #FFCD4A 0%, #F6A72A 100%)" 
                : "#fff",
              width: "225px",
              height: "95px",
              borderRadius: "20px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "left",
              padding: "10px 20px",
              marginRight: "15px",
              transition: "all 0.3s ease-in-out",
              cursor: "pointer",
              transform: isHighlighted ? "scale(1.1)" : "scale(1)", // Apply hover effect
            }}
          >
            <Typography variant="body1" sx={{ fontWeight: "bold", color: isHighlighted ? "#fff" : "#000" }}>
              {item.mainmodelname}
            </Typography>
          </Paper>
        );
      })}
    </div>
  );
}
