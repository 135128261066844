import design24 from "../../../images/design24.svg";
import image20 from "../../../images/image20.png";
import image21 from "../../../images/image21.png";
import image22 from "../../../images/image22.png";
import image23 from "../../../images/image23.svg";
import image24 from "../../../images/image24.svg";
import fellowship from "../../../images/fellowship.png";
import { motion } from "motion/react";
export default function FellowshipGrant() {
  return (
    <div className="w-full h-full realtive ">
      
      <div className="w-full flex flex-col items-center z-10 ">
        <div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="text-[#1789ef] text-4xl md:text-6xl font-bold text-center"
            style={{ textShadow: "4px 4px 6px rgba(0, 0, 0, 0.4)" }}
          >
            Fellowship Grant
          </motion.div>
          <motion.div
            whileHover={{ scale: 1.1 }}
            className="text-black text-md md:text-3xl font-normal text-center mt-2"
          >
            Fuelling the Next Generation of Entrepreneurs!
          </motion.div>
        </div>

        <div
          className="w-full h-auto md:h-[800px] bg-cover bg-top bg-no-repeat flex flex-col justify-center items-center lg:mt-30  xs:mt-20 mb-5 px-4"
          style={{ backgroundImage: "url('/bluebg.png')" }}
        >
          <div className="max-w-[1200px] w-full text-center relative">
            <img
              src={fellowship}
              className="w-auto xs:h-[210px] lg:-mt-[140px] md:-mt-[160px] xs:-mt-[80px] mx-auto"
              alt="Design"
            />

            <motion.div
              whileHover={{ scale: 1.1 }}
              className="text-[#facc15] text-3xl md:text-4xl font-bold mt-3 mb-5"
              style={{ textShadow: "4px 4px 6px rgba(0, 0, 0, 0.4)" }}
            >
              The Power of Opportunity 
            </motion.div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6 lg:gap-10">
              <motion.div
                whileHover={{ scale: 1.1 }}
                className="bg-white opacity-100  rounded-2xl flex items-center shadow-lg"
              >
                <img
                  src={image20}
                  className="w-[100px] md:w-[140px] mr-4"
                  alt="Insight"
                />
                <p className="text-black font-medium text-center">
                  Funding of up to ₹2 Crore fuels top entrepreneurial ideas with
                  a multi-crore grant.  
                </p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.1 }}
                className="bg-white opacity-100  rounded-2xl flex items-center shadow-lg"
              >
                <img
                  src={image24}
                  className="w-[100px] md:w-[140px] mr-2"
                  alt="Innovation"
                />
                <p className="text-black font-medium text-center">
                  A platform for visionaries transforms your concept into a
                  scalable venture with expert backing.  
                </p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.1 }}
                className="bg-white opacity-100  rounded-2xl flex items-center shadow-lg"
              >
                <img
                  src={image22}
                  className="w-[100px] md:w-[130px] mr-1"
                  alt="Learning"
                />
                <p className="text-black font-medium text-center">
                  Recognition and credibility let you stand out with an award
                  that validates your innovation and skill.
                </p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.1 }}
                className="bg-white opacity-100 rounded-2xl flex items-center shadow-lg"
              >
                <img
                  src={image23}
                  className="w-[100px] md:w-[140px] mr-2"
                  alt="Certifications"
                />
                <p className="text-black font-medium text-center">
                  Industry mentorship provides access to business leaders,
                  investors, and mentors to refine and accelerate your idea. 
                </p>
              </motion.div>

              <motion.div
                whileHover={{ scale: 1.1 }}
                className="flex justify-center col-span-1 md:col-span-2"
              >
                <div className="w-[280px] xs:w-[590px] bg-white opacity-100 p-5 rounded-2xl flex items-center shadow-lg">
                  <img
                    src={image21}
                    className="w-[70px] md:w-[100px] mr-4"
                    alt="Exclusive"
                  />
                  <p className="text-black font-medium text-center">
                    Global certifications from NASA, Harvard, and MIT boost your
                    credibility and add immense value to your journey.
                  </p>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
