import React from "react"
import {motion} from "motion/react"
const Heading = ({ text1, text2 }) => {
    return (
        <motion.h2 whileHover={{scale:1.3}} className="text-2xl lg:text-4xl mb-8 lg:mb-16 font-extrabold text-gray-900">
            {text1} <span className="text-orange-500">{text2}</span>
        </motion.h2>
    )
}

export default Heading