import { Card, CardContent, Typography, Grid } from "@mui/material";
import { motion, useInView } from "framer-motion";
import { useTheme } from "@mui/material/styles";
import Bar_on from "../../../images/Bar_on.png"
import { useTypewriter } from "./Textanimation"
import half_orange from "../../../images/half_orange.png"
import { useRef } from "react";
import halfgreen_Star from "../../../images/halfgreen_Star.png"
import { Paper } from '@mui/material';
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect } from "react";

export default function Bar_on_model() {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down("sm"));
    useEffect(() => {
            window.scrollTo(0, 0); // Scroll to the top of the page
          }, []);
    

    const ref1 = useRef(null);
    const ref2 = useRef(null);
 

    
    const isInView1 = useInView(ref1, { once: true });
    const isInView2 = useInView(ref2, { once: true });
    
  
    const text1 = useTypewriter(
        "  The Bar-On Model of Emotional Intelligence (EQ-i) is a pioneering framework for measuring Emotional Intelligence (EI). The model focuses on key areas like self-awareness, self-management, social awareness, and social skills, influencing education, leadership, and personal development.", 
        30,
        isInView1,
        200 
    );

    const text2 = useTypewriter(
        " The Bar-On Model of Emotional Intelligence (EQ-i) was developed by Dr. Reuven Bar-On in the 1980s as part of his doctoral research to define and measure Emotional Intelligence (EI) scientifically. It led to the creation of the Emotional Quotient Inventory (EQ-i), the first validated EI assessment. Over time, the model evolved and became widely used in education, leadership, mental health, and personal development, shaping the understanding of EI and its applications.", 
        30,
        isInView2,
        300 
    );

    
    return (
        <div
            style={{
                height: "auto",
                backgroundColor: "#fff",
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
                flexDirection: "column",
                paddingTop: "20px",
                fontFamily: 'Nunito'
            }}
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: matches ? "column" : "row",
                    alignItems: "center",
                    justifyContent: 'center',
                    width: matches ? "95%" : "90%",
                    gap: matches ? "20px" : "80px",
                    margin: "0 auto",
                }}
            >
                {/* Left Side */}

                <div style={{ textAlign: matches ? "center" : "left" }}>

                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: matches ? "center" : "flex-start",
                        }}
                    >

                        <div>
                            <div>
                                <motion.div
                                    initial={{ opacity: 0, y: -20, scale: 0.8 }}
                                    animate={{ opacity: 1, y: 0, scale: 1 }}
                                    transition={{ duration: 0.8, ease: "easeOut" }}
                                    whileHover={{ scale: 1.05 }}
                                >
                                    <Typography
                                        style={{
                                            fontFamily: "Nunito",
                                            fontWeight: 700,
                                            fontSize: matches ? "36px" : "100px",
                                            color: "#0072FF",
                                        }}
                                    >
                                        Bar-On
                                    </Typography>
                                </motion.div>
                            </div>
                            {/* Animated Curve */}
                            <div>
                                <motion.svg
                                    width={matches ? 140 : 400}
                                    height={matches ? 8 : 25}
                                    viewBox="0 0 480 30"
                                    strokeLinecap="round"
                                    style={{
                                        display: "block",
                                        marginTop: matches ? "-10px" : "-35px",
                                        marginLeft: "auto",
                                        marginRight: "auto",
                                    }}
                                >
                                    <motion.path
                                        d="M10,15 Q275,-10 470,15"
                                        stroke="#E66A35"
                                        strokeWidth={matches ? "2.5" : "8"}
                                        fill="transparent"
                                        initial={{ pathLength: 0, opacity: 0 }}
                                        animate={{ pathLength: 1, opacity: 1 }}
                                        transition={{ duration: 1.5, ease: "easeInOut" }}
                                    />
                                </motion.svg>
                            </div>
                        </div>
                    </div>



                    {/* Model */}
                    <Typography
                        style={{
                            fontFamily: "Nunito",
                            fontWeight: 700,
                            fontSize: matches ? "28px" : "80px",
                            color: "#151617",
                        }}
                    >
                        Model
                    </Typography>
                </div>

                {/* Right Side */}
                <div style={{ width: matches ? "90%" : "600px" }}>
                    <img
                        src={Bar_on}
                        alt="OCEAN Model"
                        style={{
                            width: "100%",
                            height: "auto",
                            objectFit: "contain",
                        }}
                    />
                </div>
            </div>
            <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
                 
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{ width: matches ? "95%" : "90%", margin: "0 auto" }}
            >
                {/* First Paragraph */}
                <Typography
                    ref={ref1}
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "28px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                    {text1}
                </Typography>
                </motion.div>
            </div>
            <div style={{ marginTop: matches ? '5%' : '3%', marginBottom: matches ? '5%' : '3%' }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "36px" : "68px",
                        lineHeight: "110%",
                        letterSpacing: "0px",
                        textAlign: "center",
                    }}
                >
                    Origin and Development
                </Typography>
            </div>

            <div style={{ width: matches ? '95%' : '90%', margin: '0 auto' }}>
            <motion.div
                initial={{ opacity: 0, y: 50 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 1 }}
                viewport={{ once: true }}
                style={{ width: matches ? "95%" : "90%", margin: "0 auto" }}
            >
                <Typography
                    ref={ref2}
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "28px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                    {text2}
                </Typography>
                </motion.div>
            </div>

            <div style={{ marginTop: matches ? "5%" : "3%", marginBottom: matches ? "5%" : "3%" }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "18px" : "32px",
                        lineHeight: "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        color: "#3479FF",
                    }}
                >
                    Key Components of the Bar-On Model
                </Typography>
            </div>

            <div>
                <Grid container spacing={matches ? 2 : 4} justifyContent="center" padding={matches ? 2 : 3}>

                    {/* First Row */}
                    <Grid container item spacing={matches ? 2 : 5} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 1, title: "Self-Awareness", text: "Recognizing and understanding one's own emotions. ", bg: "#E9D5FF" },
                            { id: 2, title: "Self-Management", text: "Controlling emotions, handling stress, and maintaining motivation. ", bg: "#D2A2F2" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Second Row */}
                    <Grid container item spacing={matches ? 2 : 4} justifyContent="space-between" xs={12} sm={12} md={10}>
                        {[
                            { id: 3, title: "Social Awareness", text: "Understanding and empathizing with others’ emotions. ", bg: "#FFF07B" },
                            { id: 4, title: "Social Skills", text: "Effective communication, teamwork, conflict resolution, and leadership. ", bg: "#6DBFF2" },
                        ].map((item) => (
                            <Grid item xs={12} sm={5.8} key={item.id}> {/* xs={12} sm={5.8} for spacing */}
                                <Paper
                                    elevation={2}
                                    style={{
                                        display: "flex",
                                        backgroundColor: item.bg,
                                        padding: matches ? "15px" : "20px",
                                        borderRadius: "16px",
                                        width: "100%",
                                        gap: "12px",
                                        alignItems: "flex-start",
                                        minHeight: matches ? "auto" : "110px",
                                    }}
                                >
                                    <div
                                        style={{
                                            backgroundColor: "white",
                                            borderRadius: "50%",
                                            width: "42px",
                                            height: "37px",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                            fontWeight: "bold",
                                        }}
                                    >
                                        {item.id}
                                    </div>
                                    <div>
                                        <Typography style={{ fontWeight: "bold" }}>{item.title}</Typography>
                                        <Typography>{item.text}</Typography>
                                    </div>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>

            </div>

            <div style={{ marginTop: matches ? "5%" : "3%", }}>
                <div style={{ display: 'flex' }} >
                    

                    <div style={{  width: "100vw", display: "flex", justifyContent: "center", alignItems: "center", position: "relative", padding: "20px 0" }}>
    <div
        style={{
            width: matches ? "100%" : "100%",
            paddingLeft: matches ? "0px" : "50px",
            textAlign: "center",
            display:'flex',
            justifyContent:'center',
        }}
    >
        <Typography
            sx={{
                fontFamily: "Nunito",
                fontWeight: 700,
                fontSize: matches ? "36px" : "68px",
                lineHeight: "110%",
                letterSpacing: "0px",
                textAlign: "center",
                color: "#3479FF",
            }}
        >
            Where is it Used
        </Typography>
    </div>

    {/* Image container - pushes image to the right */}
    <div style={{display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <img 
            src={halfgreen_Star} 
            alt="Star Icon"
            style={{
                display: "block",
                width: "10%",
                position: "absolute",
            }} 
        />
    </div>
</div>

                </div>
                <div
                    style={{
                        justifyContent:'center',
                        width: matches ? "95%" : "70%",
                        margin: "1.5% auto",
                        textAlign: 'left',
                        
                    }}
                >
                    <ul
                        style={{
                            fontFamily: "Nunito",
                            fontSize: matches ? "14px" : "18px",
                            lineHeight: "150%",
                            paddingLeft: "20px",
                            marginTop: "15px",
                        }}
                    >
                        <li>
                            <strong>Education :</strong> Helps students develop emotional resilience, social adaptability, and stress management.
                        </li>
                        <li>
                            <strong>Workplaces & Leadership :</strong> Used in corporate training to enhance teamwork, leadership, and decision-making.
                        </li>
                        <li>
                            <strong>Mental Health & Counseling :</strong> Supports emotional well-being, self-regulation, and interpersonal relationships.
                        </li>
                        <li>
                            <strong>Personal Growth & Coaching :</strong> Assists individuals in understanding and improving their emotional intelligence for life success.
                        </li>
                    </ul>
                </div>

            </div>
            <div style={{ marginTop:  '3%', marginBottom:  '3%' }}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 700,
                        fontSize: matches ? "26px" : "53px",
                        lineHeight: "110%",
                        letterSpacing: "0px",
                        textAlign: "center",
                    }}
                >
                   BAR-ON Model in SkillSphere & Child Development 
                </Typography>
                <div style={{paddingLeft:'5%',width:'90%'}}>
                <Typography
                    sx={{
                        fontFamily: "Nunito",
                        fontWeight: 400,
                        fontSize: matches ? "16px" : "23px",
                        lineHeight: matches ? "130%" : "100%",
                        letterSpacing: "0px",
                        textAlign: "center",
                        padding: matches ? "8px" : "20px"
                    }}
                >
                    The Bar-On Model plays a crucial role in SkillSphere assessments by helping children
                     build emotional intelligence early in life. 
                </Typography>
                </div>
            </div>
            <div style={{ width: '100%' }}>
                <Grid
                    container
                    spacing={3}
                    justifyContent="center"
                    sx={{
                        maxWidth: "100%",
                        padding: matches ? 1 : 3,
                        display: "flex",
                        flexWrap: matches ? "wrap" : "nowrap",
                        overflowX: matches ? "unset" : "auto"
                    }}
                >

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#9B76D1",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '15px' : '18px', fontWeight: 400 }}>
                                Emotional Resilience & Adaptability 
                                </Typography>
                                <Typography variant="body4">
                                Helps children manage emotions, reduce stress, and develop positive thinking for problem-solving.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#F2652A",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '15px' : '18px', fontWeight: 400 }}>
                                Leadership & Teamwork 
                                </Typography>
                                <Typography variant="body4">
                                Builds collaboration, empathy, and communication while fostering conflict resolution and strong relationships.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#44AA8B",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '15px' : '18px', fontWeight: 400 }}>
                                Decision-Making & Problem-Solving 
                                </Typography>
                                <Typography variant="body4">
                                Teaches rational decision-making, balanced choices, responsibility, and accountability.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                    <Grid item>
                        <Card sx={{
                            textAlign: 'center',
                            backgroundColor: "#F5A751",
                            color: "white",
                            borderRadius: 7,
                            width: matches ? 180 : 225,
                            height: matches ? 180 : 225,
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center"
                        }}>
                            <CardContent sx={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: matches ? '10px' : '15px' }}>
                                <Typography style={{ fontSize: matches ? '18px' : '22px', fontWeight: 400 }}>
                                Interpersonal Effectiveness 
                                </Typography>
                                <Typography variant="body4">
                                Boosts social confidence, emotional expression, friendships, and teamwork.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>

                </Grid>
            </div>
            <div style={{ display: 'flex', marginTop: '5%', marginBottom: '5%' }} >
                <div style={{ display: 'flex', alignItems: 'center' }} >
                    <img src={half_orange} style={{ display: 'block', width: matches ? '50%' : '80%' }} />
                </div>

                <div style={{ width: '100vw', display: 'flex' }} >
                    <div
                        style={{
                            width: matches ? "80%" : "80%",
                            paddingLeft: matches ? "0px" : "50px",
                            textAlign: "center",
                        }}
                    >
                        <Typography
                            sx={{
                                fontFamily: "Nunito",
                                fontWeight: 700,
                                fontSize: matches ? "20px" : "32px",
                                lineHeight: "120%",
                                color: "#0C0B0B",
                                paddingLeft:'7%'
                            }}
                        >
                            It strengthens emotional intelligence, resilience, and social skills for success in life.
                        </Typography>
                    </div>

                </div>

            </div>



        </div>
    );
}