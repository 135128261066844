import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useEffect } from 'react';
import skillspherebgvedio from "../../../../assets/skillspherebgvedio.mp4"

export default function RegisterNow() {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isTablet = useMediaQuery(theme.breakpoints.between('sm', 'md'));

    useEffect(() => {
        return () => {
            document.body.style.overflow = 'auto';
        };
    }, []);

    return (
        <div
            id="register-container"
            style={{
                width: "100%",
                minHeight: "280px",
                height: isMobile ? "200px" : isTablet ? "400px" : "500px",
                backgroundColor: "#173C7D9E",
                position: "relative",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                overflowY: "auto",
                overflowX: "hidden",
                padding: isMobile ? "5px" : "20px",
                boxSizing: "border-box",
                // marginTop: "60px"
            }}
        >
            {/* Video Background */}
            <video
                autoPlay
                muted
                loop
                style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover", 
                    zIndex: 0,
                    pointerEvents: "none"
                }}
            >
                <source src={skillspherebgvedio} type="video/mp4" />
                Your browser does not support the video tag.
            </video>


            {/* Register Button */}
            <a
                href="https://skillsphere.simplifyingskills.com/register"
                target="_blank"
                rel="noopener noreferrer"
                style={{
                    fontFamily: "Montserrat",
                    position: "absolute",
                    bottom: isMobile ? "20%" : "20%",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1,
                    backgroundColor: "#3498db",
                    color: "#fff",
                    padding: isMobile ? "4px 10px" : isTablet ? "12px 20px" : "14px 28px",
                    borderRadius: "6px",
                    fontWeight: "bold",
                    fontSize: isMobile ? "10px" : isTablet ? "16px" : "18px",
                    cursor: "pointer",
                    boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
                    textAlign: "center",
                    textDecoration: "none",
                    whiteSpace: "nowrap",
                }}
            >
                Register Now
            </a>

            {/* Text Box */}
            <div
                style={{
                    width: isMobile ? "95%" : isTablet ? "65%" : "50%",
                    position: "absolute",
                    bottom: "0",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 1,
                    background: "linear-gradient(180deg, #FFB347 0%, #FFCC33 100%)",
                    padding: isMobile ? "6px 10px" : isTablet ? "14px 24px" : "18px 36px",
                    borderRadius: "20px 20px 0px 0px",
                    fontWeight: "600",
                    fontSize: isMobile ? "10px" : isTablet ? "16px" : "20px",
                    color: "#1E1E1E",
                    textAlign: "center",
                    boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.15)",
                    whiteSpace: "normal",
                    letterSpacing: "0.5px",
                    fontFamily: "Montserrat",
                }}
            >
                India’s First Assessment That Unlocks <br /> Your Child’s True Potential
            </div>
        </div>
    );
}