import React from "react";

// Import Images
import SkillsphereAssesment from "../../../images/SkillsphereAssessment.png";
import NextGen from "../../../images/NextGen.png";
import NextGenJunior from "../../../images/NextGenJunior.png";
import productBannerSide from "../../../images/home-products-side.png";

import Heading from "../../common/heading";

const products = [
  {
    title: "Next-Gen Innovators",
    image: NextGen,
    area: "left",
    link: "nextgeni",
  },
  {
    title: "SkillSphere Assessment",
    image: SkillsphereAssesment,
    area: "top",
    link: "skillsphere-assessment",
  },
  {
    title: "Next-Gen Juniors",
    image: NextGenJunior,
    area: "bottom",
    link: "nextgenj",
  },
  
];

const ProductsSection = () => {
  // Define the animation keyframes as an inline style
  const moveDotsAnimation = {
    animation: "moveDots 3s infinite ease-in-out",
  };

  return (
    <div className="relative h-full py-8 px-4 sm:px-6 bg-gradient-to-b from-gray-100 to-gray-200 text-center overflow-x-hidden overflow-y-hidden">
      {/* Title */}
      <Heading text1="OUR" text2="PRODUCTS" />

      {/* blue background on right */}
      <img
        src={productBannerSide}
        alt="side"
        className="hidden md:block absolute top-1/2 -translate-y-1/2 -right-20 w-1/4 max-w-xs"
      />

      {/* Decorative Dots with Inline Animation */}
      <style>
        {`
          @keyframes moveDots {
            0% {
              transform: translateY(0) translateX(0);
            }
            50% {
              transform: translateY(-20px) translateX(20px);
            }
            100% {
              transform: translateY(0) translateX(0);
            }
          }
        `}
      </style>

      {/* Top Left Dots */}
      <div
        style={moveDotsAnimation}
        className="absolute left-1/4 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/4 translate-x-20 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/3 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full translate-x-8"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/3 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full translate-x-12"
      ></div>

      {/* Middle Left Dots */}
      <div
        style={moveDotsAnimation}
        className="absolute left-1/4 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-12 lg:-translate-y-4"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/4 translate-x-20 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-12 lg:-translate-y-4"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/3 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-12 lg:-translate-y-4 translate-x-8"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/3 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-12 lg:-translate-y-4 translate-x-12"
      ></div>

      {/* Bottom Left Dots */}
      <div
        style={moveDotsAnimation}
        className="absolute left-1/4 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-16 lg:-translate-y-8"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/4 translate-x-20 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-16 lg:-translate-y-8"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/3 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-16 lg:-translate-y-8 translate-x-8"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute left-1/3 top-20 md:top-40 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full -translate-y-16 lg:-translate-y-8 translate-x-12"
      ></div>

      {/* Bottom Right Dots */}
      <div
        style={moveDotsAnimation}
        className="absolute right-1/4 bottom-10 md:bottom-20 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute right-1/4 bottom-10 md:bottom-20 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full translate-x-20"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute right-1/3 bottom-10 md:bottom-20 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full translate-x-8"
      ></div>
      <div
        style={moveDotsAnimation}
        className="absolute right-1/3 bottom-10 md:bottom-20 flex space-x-2 w-2 h-2 bg-purple-300 rounded-full translate-x-12"
      ></div>

      {/* Grid Layout */}
      <div className="mt-4 gap-4 grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 place-items-center max-w-screen-xl mx-auto">
        {products.map((product, index) => (
          <ProductCard key={index} product={product} index={index} />
        ))}
      </div>
    </div>
  );
};

// Product Card Component
const ProductCard = ({ product, index }) => {
  return (
    <div
      className={`w-48 h-48 sm:w-60 sm:h-60 bg-white shadow-lg rounded-lg p-4 sm:p-8 text-center hover:shadow-2xl transition duration-300 ${
        index === 0 ? "lg:justify-self-end" : index === 2 ? "lg:justify-self-start" : ""
      }`}
      
    >
      {/* Product Image */}
      <img
        src={product.image}
        alt={product.title}
        className="w-10 h-10 sm:w-12 sm:h-12 mx-auto flex items-center justify-center text-white text-2xl"
      />

      {/* Title */}
      <h3 className="mt-4 text-lg sm:text-xl font-semibold text-gray-800">
        {product.title.split(" ").slice(0, -1).join(" ")}
      </h3>
      <h3 className="text-lg sm:text-xl font-semibold text-gray-800">
        {product.title.split(" ").pop()}
      </h3>

      {/* Read More */}
      {product.link !== "#" && (
        <a
          href={product.link}
          className="mt-2 text-blue-600 text-sm font-medium block underline underline-offset-2"
        >
          Read More
        </a>
      )}
    </div>
  );
};

export default ProductsSection;