import React from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaYoutube,
} from "react-icons/fa";
import FooterLogo from "../../images/FooterLogo.png"; // Ensure it's the transparent logo
import { Link, useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

 
const Footer = () => {
  const navigate = useNavigate();


  return (
    <footer className="bg-gray-100 text-gray-800 text-sm">
      {/* Upper Section */}
      <div className="container mx-auto px-6 py-10 grid grid-cols-2 md:grid-cols-4 gap-6 border-b pb-6 text-center lg:text-left">
        {/* About Us */}
        <div>
          <h4 className="font-bold text-blue-700 mb-3">
            <Link to="/about-us">About Us</Link>
          </h4>
          <ul className="space-y-2 text-xs">
            <li className="hover:font-bold">
              <HashLink to="/about-us#whyus" smooth>
                Why Us
              </HashLink>
            </li>
            {/* <li className="hover:font-bold sm:hidden">
              <HashLink to="/contact" smooth>
                Contact Us
              </HashLink>
            </li> */}
            <li className="hover:font-bold">
              <HashLink to="/about-us#vision" smooth>
                Mission
              </HashLink>
            </li>
            <li className="hover:font-bold">
              <HashLink to="/about-us#vision" smooth>
                Vision
              </HashLink>
            </li>
            <li className="hover:font-bold">
              <HashLink to="/about-us#values" smooth>
                Values
              </HashLink>
            </li>
            <li className="hover:font-bold">
              <HashLink to="/about-us#whatwedo" smooth>
                What we do
              </HashLink>
            </li>
            <li className="hover:font-bold">
              <HashLink to="/about-us#team" smooth>
                Team
              </HashLink>
            </li>
            {/* <li className="hover:font-bold">
              <HashLink to="/about-us#whyus" smooth>
             
                Careers
              
            </li> */}
            <li className="hover:font-bold sm:hidden">
              <HashLink to="/contact" smooth>
                Contact Us
              </HashLink>
            </li>
          </ul>
        </div>

        {/* Research */}
        <div>
          <h4 className="font-bold text-blue-700 mb-3">Research</h4>
          <ul className="space-y-2 text-xs">
            <li className="hover:font-bold">NEP 2020</li>
            <li className="hover:font-bold">Global Exposure</li>
            <li className="hover:font-bold">Fellowship</li>
            <li className="hover:font-bold">STEM</li>
            <Link to='/ocean'> <li className="hover:font-bold mt-2">Ocean Model</li> </Link>
            <Link to='/riasec'> <li className="hover:font-bold mt-2">RIASEC</li></Link>
            <Link to='/baronmodel'> <li className="hover:font-bold mt-2">Bar-On Model</li></Link>
            <li className="hover:font-bold">CORE</li>
            <li className="hover:font-bold">DAT</li>
          </ul>
        </div>

        {/* Other Links */}
        <div>
          <h4 className="font-bold text-blue-700 mb-3">Other’s</h4>
          <ul className="space-y-2 text-xs">
            <Link to="/blog">
              {" "}
              <li className="hover:font-bold">Blogs</li>
            </Link>
            <li className="hover:font-bold">FAQs</li>
            <li className="hover:font-bold">
              <a href="/terms-and-conditions">Terms & Conditions</a>
            </li>
            {/* <li className="hover:font-bold">
              <HashLink to="/#gallery" smooth>
                Gallery
              </HashLink>
            </li> */}
            <li className="hover:font-bold">
              <a href="/privacy-policy">Privacy Policy</a>
            </li>
          </ul>
        </div>

        {/* Clubs */}
        <div>
          <h4 className="font-bold text-blue-700 mb-3">Clubs</h4>
          <ul className="space-y-2 text-xs">
            <li className="hover:font-bold">Entrepreneurship League</li>
            <li className="hover:font-bold">Engineering Explorers</li>
            <li className="hover:font-bold">Medical Mavericks</li>
            <li className="hover:font-bold">Artistic Visionaries</li>
            <li className="hover:font-bold">Business Trailblazers</li>
            <li className="hover:font-bold">Administrative Achievers</li>
          </ul>
        </div>
      </div>

      {/* Lower Section */}
      <div className="container mx-auto px-6 py-6 flex flex-col md:flex-row items-center justify-between">
        {/* Logo & Text Section */}
        <div className="flex items-center space-x-4">
          <img
            src={FooterLogo}
            alt="Logo"
            className="w-32 h-auto object-contain"
          />
          <div>
            <h3 className="font-bold text-lg text-blue-700">
              SIMPLIFYING SKILLS
            </h3>
            <p className="text-gray-500 mt-1">TRANSFORMING EDUCATION</p>
            <p className="mt-2 text-gray-700 font-semibold">
              Subscribe to Our Newsletter for Latest Updates
            </p>
          </div>
        </div>

        {/* Social Media */}
        <div className="flex items-center space-x-4 xs:mt-3">
          <span className="font-bold">FOLLOW US ON:</span>
          <a
            href="https://www.youtube.com/@simplifyingskills"
            target="_blank"
            rel="noreferrer"
            className="text-red-600 text-xl"
          >
            <FaYoutube />
          </a>
          <a
            href="https://www.facebook.com/SimplifyingSkillsConsultants"
            target="_blank"
            rel="noreferrer"
            className="text-blue-600 text-xl"
          >
            <FaFacebookF />
          </a>
          <a
            href="https://www.instagram.com/simplifying_skills/"
            target="_blank"
            rel="noreferrer"
            className="text-pink-500 text-xl"
          >
            <FaInstagram />
          </a>
          <a
            href="https://www.linkedin.com/company/simplifying-skills-consultants/"
            target="_blank"
            rel="noreferrer"
            className="text-blue-500 text-xl"
          >
            <FaLinkedinIn />
          </a>
        </div>
      </div>

      {/* Bottom Bar */}
      <div className="bg-blue-950 text-white py-3 text-xs flex justify-center items-center relative">
        <span className="absolute right-1 sm:left-[62%] transform -translate-x-1/2">
          ©2025 All Rights Reserved Simplifying Skills
        </span>
        <button onClick={() => navigate("/login")} className="absolute right-6 px-1  font-semibold bg-white text-blue-950 rounded hover:bg-gray-200 transition">
          Admin
        </button>
      </div>
    </footer>
  );
};

export default Footer;
